const unstyledStyle = () => {
  return {
    borderWidth: 1,
    borderColor: 'transparent',
    _focus: {
      borderColor: 'primary.500',
    },
    _hover: {
      borderColor: 'text.50',
    },
    _disabled: {
      bg: 'background.700',
      borderColor: 'background.500',
      color: 'text.500',
      opacity: 1,
    },
    _invalid: {
      borderColor: 'error.500',
    },
  }
}

const variants = {
  outline: unstyledStyle,
  underlined: unstyledStyle,
  rounded: unstyledStyle,
  filled: unstyledStyle,
  unstyled: unstyledStyle,
}

const sizes = {
  '2xl': { fontSize: '2xl' },
  xl: { fontSize: 'xl' },
  lg: { fontSize: 'lg' },
  md: { fontSize: 'md' },
  sm: { fontSize: 'sm' },
  xs: { fontSize: 'xs' },
}

const defaultProps = {
  isFullWidth: true,
  variant: 'unstyled',
  autoCapitalize: 'none',
  bg: 'background.600',
  fontSize: 'md',
  color: 'text.50',
  selectionColor: 'text.50',
  // style: { height: '100%' },
  // h: 'auto',
  // h: 10,
  m: 0,
  borderRadius: 4,
  px: 4,
  py: 3,
  _hover: {
    borderWidth: 1,
    borderColor: 'text.50',
  },
}

export default {
  baseStyle: {},
  defaultProps,
  variants,
  sizes,
}
