import React from 'react'

import { Text, View } from 'native-base'
import { ICalculationVentilation } from '..'

import { Defs, Path, ClipPath, G, Rect } from 'react-native-svg'
import { addInchesMark, formatNumber, NULL_VALUE_NUMBERS } from '../../../utils'

import RoofImage from './BaseDiagramImage'

export interface IVentilationDiagramProps extends ICalculationVentilation {}

const DEFAULT_INPUTS = {} as IVentilationDiagramProps['inputs']
const DEFAULT_RESULTS = {} as IVentilationDiagramProps['results']

const strokeDasharray = ' 6'
const strokeWidth = '5'

const Meassures = () => (
  <>
    <Defs>
      <ClipPath id="__lottie_element_2">
        <Rect width="610" height="650" x="0" y="0" />
      </ClipPath>
    </Defs>
    <G clipPath="url(#__lottie_element_2)">
      <G
        transform="matrix(1,0,0,1,324.8940124511719,131.01499938964844)"
        opacity="1">
        <G opacity="1" transform="matrix(1,0,0,1,0,0)">
          <Path
            strokeLinecap="butt"
            fillOpacity="0"
            stroke="rgb(255,184,41)"
            d="M0,267.9159851074219 C0,267.9159851074219 209.39500427246094,240.77000427246094 209.39500427246094,240.77000427246094"
            strokeWidth={strokeWidth}
            strokeOpacity="1"
            strokeDasharray={strokeDasharray}
          />
        </G>
      </G>
    </G>
  </>
)

const VentilationDiagram = ({
  inputs = DEFAULT_INPUTS,
  results = DEFAULT_RESULTS,
}: IVentilationDiagramProps) => {
  const atticAreaText = addInchesMark(
    results.atticArea ? formatNumber(results.atticArea, 2) : NULL_VALUE_NUMBERS,
    ' SF',
  )
  return (
    <View position="relative" overflow="hidden" w="100%" h="auto" padding="1.5">
      <RoofImage MeassureComponent={Meassures} />
      <View position="absolute" top="55%" left="35%">
        <Text
          fontSize={{ base: 'xs', sm: 'lg' }}
          fontWeight="bold"
          color="primary.500"
          zIndex={100}>
          {inputs.roofPitch ? `${inputs.roofPitch}/12` : NULL_VALUE_NUMBERS}
        </Text>
      </View>
      <View position="absolute" top="63%" left="65%">
        <Text
          fontSize={{ base: 'xs', sm: 'lg' }}
          fontWeight="bold"
          color="primary.500">
          {atticAreaText}
        </Text>
      </View>
      <View
        position="absolute"
        wordBreak="break-word"
        maxWidth={{
          base: '100',
          sm: '200',
        }}
        top={{ base: '40%', sm: '45%' }}
        left="60%">
        <Text
          fontSize={{ base: 'xs', sm: 'lg' }}
          fontWeight="bold"
          color="primary.500"
          textAlign="center">
          ATTIC FLOOR AREA
        </Text>
      </View>
    </View>
  )
}

export default VentilationDiagram
