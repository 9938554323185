export const ANT_DESIGN_ICON_NAMES = [
  'accountbookfilled',
  'accountbookoutlined',
  'accountbooktwotone',
  'aimoutlined',
  'alertfilled',
  'alertoutlined',
  'alerttwotone',
  'alibabaoutlined',
  'aligncenteroutlined',
  'alignleftoutlined',
  'alignrightoutlined',
  'alipaycirclefilled',
  'alipaycircleoutlined',
  'alipayoutlined',
  'alipaysquarefilled',
  'aliwangwangfilled',
  'aliwangwangoutlined',
  'aliyunoutlined',
  'amazoncirclefilled',
  'amazonoutlined',
  'amazonsquarefilled',
  'androidfilled',
  'androidoutlined',
  'antcloudoutlined',
  'antdesignoutlined',
  'apartmentoutlined',
  'apifilled',
  'apioutlined',
  'apitwotone',
  'applefilled',
  'appleoutlined',
  'appstoreaddoutlined',
  'appstorefilled',
  'appstoreoutlined',
  'appstoretwotone',
  'areachartoutlined',
  'arrowdownoutlined',
  'arrowleftoutlined',
  'arrowrightoutlined',
  'arrowupoutlined',
  'arrowsaltoutlined',
  'audiofilled',
  'audiomutedoutlined',
  'audiooutlined',
  'audiotwotone',
  'auditoutlined',
  'backwardfilled',
  'backwardoutlined',
  'bankfilled',
  'bankoutlined',
  'banktwotone',
  'barchartoutlined',
  'barcodeoutlined',
  'barsoutlined',
  'behancecirclefilled',
  'behanceoutlined',
  'behancesquarefilled',
  'behancesquareoutlined',
  'bellfilled',
  'belloutlined',
  'belltwotone',
  'bgcolorsoutlined',
  'blockoutlined',
  'boldoutlined',
  'bookfilled',
  'bookoutlined',
  'booktwotone',
  'borderbottomoutlined',
  'borderhorizontaloutlined',
  'borderinneroutlined',
  'borderleftoutlined',
  'borderouteroutlined',
  'borderoutlined',
  'borderrightoutlined',
  'bordertopoutlined',
  'borderverticleoutlined',
  'borderlesstableoutlined',
  'boxplotfilled',
  'boxplotoutlined',
  'boxplottwotone',
  'branchesoutlined',
  'bugfilled',
  'bugoutlined',
  'bugtwotone',
  'buildfilled',
  'buildoutlined',
  'buildtwotone',
  'bulbfilled',
  'bulboutlined',
  'bulbtwotone',
  'calculatorfilled',
  'calculatoroutlined',
  'calculatortwotone',
  'calendarfilled',
  'calendaroutlined',
  'calendartwotone',
  'camerafilled',
  'cameraoutlined',
  'cameratwotone',
  'carfilled',
  'caroutlined',
  'cartwotone',
  'caretdownfilled',
  'caretdownoutlined',
  'caretleftfilled',
  'caretleftoutlined',
  'caretrightfilled',
  'caretrightoutlined',
  'caretupfilled',
  'caretupoutlined',
  'carryoutfilled',
  'carryoutoutlined',
  'carryouttwotone',
  'checkcirclefilled',
  'checkcircleoutlined',
  'checkcircletwotone',
  'checkoutlined',
  'checksquarefilled',
  'checksquareoutlined',
  'checksquaretwotone',
  'chromefilled',
  'chromeoutlined',
  'cicirclefilled',
  'cicircleoutlined',
  'cicircletwotone',
  'cioutlined',
  'citwotone',
  'clearoutlined',
  'clockcirclefilled',
  'clockcircleoutlined',
  'clockcircletwotone',
  'closecirclefilled',
  'closecircleoutlined',
  'closecircletwotone',
  'closeoutlined',
  'closesquarefilled',
  'closesquareoutlined',
  'closesquaretwotone',
  'clouddownloadoutlined',
  'cloudfilled',
  'cloudoutlined',
  'cloudserveroutlined',
  'cloudsyncoutlined',
  'cloudtwotone',
  'clouduploadoutlined',
  'clusteroutlined',
  'codefilled',
  'codeoutlined',
  'codesandboxcirclefilled',
  'codesandboxoutlined',
  'codesandboxsquarefilled',
  'codetwotone',
  'codepencirclefilled',
  'codepencircleoutlined',
  'codepenoutlined',
  'codepensquarefilled',
  'coffeeoutlined',
  'columnheightoutlined',
  'columnwidthoutlined',
  'commentoutlined',
  'compassfilled',
  'compassoutlined',
  'compasstwotone',
  'compressoutlined',
  'consolesqloutlined',
  'contactsfilled',
  'contactsoutlined',
  'contactstwotone',
  'containerfilled',
  'containeroutlined',
  'containertwotone',
  'controlfilled',
  'controloutlined',
  'controltwotone',
  'copyfilled',
  'copyoutlined',
  'copytwotone',
  'copyrightcirclefilled',
  'copyrightcircleoutlined',
  'copyrightcircletwotone',
  'copyrightoutlined',
  'copyrighttwotone',
  'creditcardfilled',
  'creditcardoutlined',
  'creditcardtwotone',
  'crownfilled',
  'crownoutlined',
  'crowntwotone',
  'customerservicefilled',
  'customerserviceoutlined',
  'customerservicetwotone',
  'dashoutlined',
  'dashboardfilled',
  'dashboardoutlined',
  'dashboardtwotone',
  'databasefilled',
  'databaseoutlined',
  'databasetwotone',
  'deletecolumnoutlined',
  'deletefilled',
  'deleteoutlined',
  'deleterowoutlined',
  'deletetwotone',
  'deliveredprocedureoutlined',
  'deploymentunitoutlined',
  'desktopoutlined',
  'difffilled',
  'diffoutlined',
  'difftwotone',
  'dingdingoutlined',
  'dingtalkcirclefilled',
  'dingtalkoutlined',
  'dingtalksquarefilled',
  'disconnectoutlined',
  'dislikefilled',
  'dislikeoutlined',
  'disliketwotone',
  'dollarcirclefilled',
  'dollarcircleoutlined',
  'dollarcircletwotone',
  'dollaroutlined',
  'dollartwotone',
  'dotchartoutlined',
  'doubleleftoutlined',
  'doublerightoutlined',
  'downcirclefilled',
  'downcircleoutlined',
  'downcircletwotone',
  'downoutlined',
  'downsquarefilled',
  'downsquareoutlined',
  'downsquaretwotone',
  'downloadoutlined',
  'dragoutlined',
  'dribbblecirclefilled',
  'dribbbleoutlined',
  'dribbblesquarefilled',
  'dribbblesquareoutlined',
  'dropboxcirclefilled',
  'dropboxoutlined',
  'dropboxsquarefilled',
  'editfilled',
  'editoutlined',
  'edittwotone',
  'ellipsisoutlined',
  'enteroutlined',
  'environmentfilled',
  'environmentoutlined',
  'environmenttwotone',
  'eurocirclefilled',
  'eurocircleoutlined',
  'eurocircletwotone',
  'eurooutlined',
  'eurotwotone',
  'exceptionoutlined',
  'exclamationcirclefilled',
  'exclamationcircleoutlined',
  'exclamationcircletwotone',
  'exclamationoutlined',
  'expandaltoutlined',
  'expandoutlined',
  'experimentfilled',
  'experimentoutlined',
  'experimenttwotone',
  'exportoutlined',
  'eyefilled',
  'eyeinvisiblefilled',
  'eyeinvisibleoutlined',
  'eyeinvisibletwotone',
  'eyeoutlined',
  'eyetwotone',
  'facebookfilled',
  'facebookoutlined',
  'falloutlined',
  'fastbackwardfilled',
  'fastbackwardoutlined',
  'fastforwardfilled',
  'fastforwardoutlined',
  'fieldbinaryoutlined',
  'fieldnumberoutlined',
  'fieldstringoutlined',
  'fieldtimeoutlined',
  'fileaddfilled',
  'fileaddoutlined',
  'fileaddtwotone',
  'filedoneoutlined',
  'fileexcelfilled',
  'fileexceloutlined',
  'fileexceltwotone',
  'fileexclamationfilled',
  'fileexclamationoutlined',
  'fileexclamationtwotone',
  'filefilled',
  'filegifoutlined',
  'fileimagefilled',
  'fileimageoutlined',
  'fileimagetwotone',
  'filejpgoutlined',
  'filemarkdownfilled',
  'filemarkdownoutlined',
  'filemarkdowntwotone',
  'fileoutlined',
  'filepdffilled',
  'filepdfoutlined',
  'filepdftwotone',
  'filepptfilled',
  'filepptoutlined',
  'fileppttwotone',
  'fileprotectoutlined',
  'filesearchoutlined',
  'filesyncoutlined',
  'filetextfilled',
  'filetextoutlined',
  'filetexttwotone',
  'filetwotone',
  'fileunknownfilled',
  'fileunknownoutlined',
  'fileunknowntwotone',
  'filewordfilled',
  'filewordoutlined',
  'filewordtwotone',
  'filezipfilled',
  'filezipoutlined',
  'fileziptwotone',
  'filterfilled',
  'filteroutlined',
  'filtertwotone',
  'firefilled',
  'fireoutlined',
  'firetwotone',
  'flagfilled',
  'flagoutlined',
  'flagtwotone',
  'folderaddfilled',
  'folderaddoutlined',
  'folderaddtwotone',
  'folderfilled',
  'folderopenfilled',
  'folderopenoutlined',
  'folderopentwotone',
  'folderoutlined',
  'foldertwotone',
  'folderviewoutlined',
  'fontcolorsoutlined',
  'fontsizeoutlined',
  'forkoutlined',
  'formoutlined',
  'formatpainterfilled',
  'formatpainteroutlined',
  'forwardfilled',
  'forwardoutlined',
  'frownfilled',
  'frownoutlined',
  'frowntwotone',
  'fullscreenexitoutlined',
  'fullscreenoutlined',
  'functionoutlined',
  'fundfilled',
  'fundoutlined',
  'fundprojectionscreenoutlined',
  'fundtwotone',
  'fundviewoutlined',
  'funnelplotfilled',
  'funnelplotoutlined',
  'funnelplottwotone',
  'gatewayoutlined',
  'gifoutlined',
  'giftfilled',
  'giftoutlined',
  'gifttwotone',
  'githubfilled',
  'githuboutlined',
  'gitlabfilled',
  'gitlaboutlined',
  'globaloutlined',
  'goldfilled',
  'goldoutlined',
  'goldtwotone',
  'goldenfilled',
  'googlecirclefilled',
  'googleoutlined',
  'googlepluscirclefilled',
  'googleplusoutlined',
  'googleplussquarefilled',
  'googlesquarefilled',
  'groupoutlined',
  'hddfilled',
  'hddoutlined',
  'hddtwotone',
  'heartfilled',
  'heartoutlined',
  'hearttwotone',
  'heatmapoutlined',
  'highlightfilled',
  'highlightoutlined',
  'highlighttwotone',
  'historyoutlined',
  'holderoutlined',
  'homefilled',
  'homeoutlined',
  'hometwotone',
  'hourglassfilled',
  'hourglassoutlined',
  'hourglasstwotone',
  'html5filled',
  'html5outlined',
  'html5twotone',
  'idcardfilled',
  'idcardoutlined',
  'idcardtwotone',
  'iecirclefilled',
  'ieoutlined',
  'iesquarefilled',
  'importoutlined',
  'inboxoutlined',
  'infocirclefilled',
  'infocircleoutlined',
  'infocircletwotone',
  'infooutlined',
  'insertrowaboveoutlined',
  'insertrowbelowoutlined',
  'insertrowleftoutlined',
  'insertrowrightoutlined',
  'instagramfilled',
  'instagramoutlined',
  'insurancefilled',
  'insuranceoutlined',
  'insurancetwotone',
  'interactionfilled',
  'interactionoutlined',
  'interactiontwotone',
  'issuescloseoutlined',
  'italicoutlined',
  'keyoutlined',
  'laptopoutlined',
  'layoutfilled',
  'layoutoutlined',
  'layouttwotone',
  'leftcirclefilled',
  'leftcircleoutlined',
  'leftcircletwotone',
  'leftoutlined',
  'leftsquarefilled',
  'leftsquareoutlined',
  'leftsquaretwotone',
  'likefilled',
  'likeoutlined',
  'liketwotone',
  'linechartoutlined',
  'lineheightoutlined',
  'lineoutlined',
  'linkoutlined',
  'linkedinfilled',
  'linkedinoutlined',
  'loading3quartersoutlined',
  'loadingoutlined',
  'lockfilled',
  'lockoutlined',
  'locktwotone',
  'loginoutlined',
  'logoutoutlined',
  'maccommandfilled',
  'maccommandoutlined',
  'mailfilled',
  'mailoutlined',
  'mailtwotone',
  'manoutlined',
  'medicineboxfilled',
  'medicineboxoutlined',
  'medicineboxtwotone',
  'mediumcirclefilled',
  'mediumoutlined',
  'mediumsquarefilled',
  'mediumworkmarkoutlined',
  'mehfilled',
  'mehoutlined',
  'mehtwotone',
  'menufoldoutlined',
  'menuoutlined',
  'menuunfoldoutlined',
  'mergecellsoutlined',
  'messagefilled',
  'messageoutlined',
  'messagetwotone',
  'minuscirclefilled',
  'minuscircleoutlined',
  'minuscircletwotone',
  'minusoutlined',
  'minussquarefilled',
  'minussquareoutlined',
  'minussquaretwotone',
  'mobilefilled',
  'mobileoutlined',
  'mobiletwotone',
  'moneycollectfilled',
  'moneycollectoutlined',
  'moneycollecttwotone',
  'monitoroutlined',
  'moreoutlined',
  'nodecollapseoutlined',
  'nodeexpandoutlined',
  'nodeindexoutlined',
  'notificationfilled',
  'notificationoutlined',
  'notificationtwotone',
  'numberoutlined',
  'onetooneoutlined',
  'orderedlistoutlined',
  'paperclipoutlined',
  'partitionoutlined',
  'pausecirclefilled',
  'pausecircleoutlined',
  'pausecircletwotone',
  'pauseoutlined',
  'paycirclefilled',
  'paycircleoutlined',
  'percentageoutlined',
  'phonefilled',
  'phoneoutlined',
  'phonetwotone',
  'piccenteroutlined',
  'picleftoutlined',
  'picrightoutlined',
  'picturefilled',
  'pictureoutlined',
  'picturetwotone',
  'piechartfilled',
  'piechartoutlined',
  'piecharttwotone',
  'playcirclefilled',
  'playcircleoutlined',
  'playcircletwotone',
  'playsquarefilled',
  'playsquareoutlined',
  'playsquaretwotone',
  'pluscirclefilled',
  'pluscircleoutlined',
  'pluscircletwotone',
  'plusoutlined',
  'plussquarefilled',
  'plussquareoutlined',
  'plussquaretwotone',
  'poundcirclefilled',
  'poundcircleoutlined',
  'poundcircletwotone',
  'poundoutlined',
  'poweroffoutlined',
  'printerfilled',
  'printeroutlined',
  'printertwotone',
  'profilefilled',
  'profileoutlined',
  'profiletwotone',
  'projectfilled',
  'projectoutlined',
  'projecttwotone',
  'propertysafetyfilled',
  'propertysafetyoutlined',
  'propertysafetytwotone',
  'pullrequestoutlined',
  'pushpinfilled',
  'pushpinoutlined',
  'pushpintwotone',
  'qqcirclefilled',
  'qqoutlined',
  'qqsquarefilled',
  'qrcodeoutlined',
  'questioncirclefilled',
  'questioncircleoutlined',
  'questioncircletwotone',
  'questionoutlined',
  'radarchartoutlined',
  'radiusbottomleftoutlined',
  'radiusbottomrightoutlined',
  'radiussettingoutlined',
  'radiusupleftoutlined',
  'radiusuprightoutlined',
  'readfilled',
  'readoutlined',
  'reconciliationfilled',
  'reconciliationoutlined',
  'reconciliationtwotone',
  'redenvelopefilled',
  'redenvelopeoutlined',
  'redenvelopetwotone',
  'redditcirclefilled',
  'redditoutlined',
  'redditsquarefilled',
  'redooutlined',
  'reloadoutlined',
  'restfilled',
  'restoutlined',
  'resttwotone',
  'retweetoutlined',
  'rightcirclefilled',
  'rightcircleoutlined',
  'rightcircletwotone',
  'rightoutlined',
  'rightsquarefilled',
  'rightsquareoutlined',
  'rightsquaretwotone',
  'riseoutlined',
  'robotfilled',
  'robotoutlined',
  'rocketfilled',
  'rocketoutlined',
  'rockettwotone',
  'rollbackoutlined',
  'rotateleftoutlined',
  'rotaterightoutlined',
  'safetycertificatefilled',
  'safetycertificateoutlined',
  'safetycertificatetwotone',
  'safetyoutlined',
  'savefilled',
  'saveoutlined',
  'savetwotone',
  'scanoutlined',
  'schedulefilled',
  'scheduleoutlined',
  'scheduletwotone',
  'scissoroutlined',
  'searchoutlined',
  'securityscanfilled',
  'securityscanoutlined',
  'securityscantwotone',
  'selectoutlined',
  'sendoutlined',
  'settingfilled',
  'settingoutlined',
  'settingtwotone',
  'shakeoutlined',
  'sharealtoutlined',
  'shopfilled',
  'shopoutlined',
  'shoptwotone',
  'shoppingcartoutlined',
  'shoppingfilled',
  'shoppingoutlined',
  'shoppingtwotone',
  'shrinkoutlined',
  'signalfilled',
  'sisternodeoutlined',
  'sketchcirclefilled',
  'sketchoutlined',
  'sketchsquarefilled',
  'skinfilled',
  'skinoutlined',
  'skintwotone',
  'skypefilled',
  'skypeoutlined',
  'slackcirclefilled',
  'slackoutlined',
  'slacksquarefilled',
  'slacksquareoutlined',
  'slidersfilled',
  'slidersoutlined',
  'sliderstwotone',
  'smalldashoutlined',
  'smilefilled',
  'smileoutlined',
  'smiletwotone',
  'snippetsfilled',
  'snippetsoutlined',
  'snippetstwotone',
  'solutionoutlined',
  'sortascendingoutlined',
  'sortdescendingoutlined',
  'soundfilled',
  'soundoutlined',
  'soundtwotone',
  'splitcellsoutlined',
  'starfilled',
  'staroutlined',
  'startwotone',
  'stepbackwardfilled',
  'stepbackwardoutlined',
  'stepforwardfilled',
  'stepforwardoutlined',
  'stockoutlined',
  'stopfilled',
  'stopoutlined',
  'stoptwotone',
  'strikethroughoutlined',
  'subnodeoutlined',
  'swapleftoutlined',
  'swapoutlined',
  'swaprightoutlined',
  'switcherfilled',
  'switcheroutlined',
  'switchertwotone',
  'syncoutlined',
  'tableoutlined',
  'tabletfilled',
  'tabletoutlined',
  'tablettwotone',
  'tagfilled',
  'tagoutlined',
  'tagtwotone',
  'tagsfilled',
  'tagsoutlined',
  'tagstwotone',
  'taobaocirclefilled',
  'taobaocircleoutlined',
  'taobaooutlined',
  'taobaosquarefilled',
  'teamoutlined',
  'thunderboltfilled',
  'thunderboltoutlined',
  'thunderbolttwotone',
  'totopoutlined',
  'toolfilled',
  'tooloutlined',
  'tooltwotone',
  'trademarkcirclefilled',
  'trademarkcircleoutlined',
  'trademarkcircletwotone',
  'trademarkoutlined',
  'transactionoutlined',
  'translationoutlined',
  'trophyfilled',
  'trophyoutlined',
  'trophytwotone',
  'twittercirclefilled',
  'twitteroutlined',
  'twittersquarefilled',
  'underlineoutlined',
  'undooutlined',
  'ungroupoutlined',
  'unlockfilled',
  'unlockoutlined',
  'unlocktwotone',
  'unorderedlistoutlined',
  'upcirclefilled',
  'upcircleoutlined',
  'upcircletwotone',
  'upoutlined',
  'upsquarefilled',
  'upsquareoutlined',
  'upsquaretwotone',
  'uploadoutlined',
  'usbfilled',
  'usboutlined',
  'usbtwotone',
  'useraddoutlined',
  'userdeleteoutlined',
  'useroutlined',
  'userswitchoutlined',
  'usergroupaddoutlined',
  'usergroupdeleteoutlined',
  'verifiedoutlined',
  'verticalalignbottomoutlined',
  'verticalalignmiddleoutlined',
  'verticalaligntopoutlined',
  'verticalleftoutlined',
  'verticalrightoutlined',
  'videocameraaddoutlined',
  'videocamerafilled',
  'videocameraoutlined',
  'videocameratwotone',
  'walletfilled',
  'walletoutlined',
  'wallettwotone',
  'warningfilled',
  'warningoutlined',
  'warningtwotone',
  'wechatfilled',
  'wechatoutlined',
  'weibocirclefilled',
  'weibocircleoutlined',
  'weibooutlined',
  'weibosquarefilled',
  'weibosquareoutlined',
  'whatsappoutlined',
  'wifioutlined',
  'windowsfilled',
  'windowsoutlined',
  'womanoutlined',
  'yahoofilled',
  'yahoooutlined',
  'youtubefilled',
  'youtubeoutlined',
  'yuquefilled',
  'yuqueoutlined',
  'zhihucirclefilled',
  'zhihuoutlined',
  'zhihusquarefilled',
  'zoominoutlined',
  'zoomoutoutlined',
] as const

export type AntDesignNames = typeof ANT_DESIGN_ICON_NAMES[number]

export interface IAntDesignNativeIconProps {
  name: any
  size?: number
  color?: string
}
