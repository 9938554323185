import React from 'react';
import { Text, Pressable, Link, useBreakpointValue } from 'native-base';
import { isWeb } from '../../../utils'

const extractStringAndAttributes = (input) => {
  const pattern = /\[(.*?)\]\{:(.*?)\}/;
  const match = input.match(pattern);

  if (match && match.length > 2) {
    try {
      const stringInsideBrackets = match[1];
      const attributesStr = match[2];

      // Split on spaces that are not within quotes
      const attributes = attributesStr.match(/(?:[^\s"]+|"[^"]*")+/g);
      const attributesObj = {};

      attributes.forEach(attribute => {
        let [key, value] = attribute.split('=');

        // If the value is enclosed in quotes, strip the quotes
        if (value.startsWith('"') && value.endsWith('"')) {
          value = value.slice(1, -1);
        }
        // If the value is 'true' or 'false' (case insensitive), convert it to a boolean
        else if (value.toLowerCase() === 'true') {
          value = true;
        } else if (value.toLowerCase() === 'false') {
          value = false;
        }
        // If the value is a number, convert it to a number
        else if (!isNaN(value)) {
          value = parseFloat(value);
        }

        attributesObj[key] = value;
      });

      return {
        attributes: attributesObj,
        string: stringInsideBrackets
      };
    } catch (e) {
      return {
        attributes: {},
        string: ''
      };
    }

  } else {
    return {
      attributes: {},
      string: ''
    };
  }
}

/**
 * Extracts art direction values from a string pattern.
 *
 * @param {string} input - The input string containing art direction information.
 * E.g., '{@ lg:hello}', '{@ base:break|md:hello medium|lg: it\'s large }'
 * 
 * @returns {string} The content direction matched for the current breakpoint.
 * E.g., ' ', 'hello', '  it\'s large '
 *
 * @example
 * 
 * const result = extractArtDirection('{@ lg:hello}');
 * // Output when lg breakpoint is active: 'hello'
 *
 * const result = extractArtDirection('{@ lg: hello }');
 * // Output when lg breakpoint is active: ' hello '
 *
 * const result = extractArtDirection("{@ base:break|md:hello medium|lg: it's large }");
 * // Output when lg breakpoint is active: '  it\'s large '
 *
 * const result = extractArtDirection('{@ base:break|md:"hell& o" m12 *edium|lg: it\'s large }');
 * // Output when md breakpoint is active: '"hell& o" m12 *edium'
 */
const extractArtDirection = (input) => {
  const directivePattern = /\{@ (.+?)\}/;
  const directiveMatch = input.match(directivePattern);

  const defaultBreakPointValue = '';
  const contentDirectionOptions = {
    break: '\n',
    space: ' ',
  };

  const breakPointValues = {
    base: defaultBreakPointValue,
  };

  if (directiveMatch && directiveMatch.length > 1) {
    const directives = directiveMatch[1].split('|');
    directives.forEach((directive) => {
      const pattern = /(\w+):([^}]+)/;

      const match = directive.match(pattern);
      if (match && match.length > 2) {
        const sizeDirective = match[1];
        const contentDirection = match[2];
        breakPointValues[sizeDirective] = contentDirectionOptions[contentDirection] || contentDirection;
      }
    });
  } else {
    console.warn("Invalid input string for extractArtDirection. Using default values.");
  }

  return useBreakpointValue(breakPointValues);
}

const MarkdownText = ({
  children,
  omitNextSpace = false,
  onProcessLink = (input) => console.log(input),
  ...props
}) => {
  const regex = /(\*\*[^*]*?\*\*|__[^_]*?__|_[^_]*?_|~~[^~]*?~~|\[[^\]]*?\]\([^)]*?\)|\[[^\]]*?\]\{[^}]*?\}|\{@ \w+:[^\}]+\})/g;

  if (typeof children !== 'string') return children

  const chunks = children.split(regex);

  if (!props.lineHeight) {
    props.lineHeight = "1.2em"
  }

  return (
    <Text {...props}>
      {chunks.map((chunk, index) => {
        if (!chunk) return
        let markdownProps = {};

        if (chunk.startsWith('{@ ') && chunk.endsWith('}')) {
          chunk = extractArtDirection(chunk);
          return <MarkdownText {...props}>{chunk}</MarkdownText>
        } else if (chunk.startsWith('**') && chunk.endsWith('**')) {
          markdownProps.bold = true;
          chunk = chunk.substring(2, chunk.length - 2);
        } else if (chunk.startsWith('__') && chunk.endsWith('__')) {
          markdownProps.underline = true;
          chunk = chunk.substring(2, chunk.length - 2);
        } else if (chunk.startsWith('_') && chunk.endsWith('_')) {
          markdownProps.italic = true;
          chunk = chunk.substring(1, chunk.length - 1);
        } else if (chunk.startsWith('~~') && chunk.endsWith('~~')) {
          markdownProps.strikeThrough = true;
          chunk = chunk.substring(2, chunk.length - 2);
        } else if (chunk.startsWith('[') && chunk.endsWith('}')) {
          const { string, attributes } = extractStringAndAttributes(chunk)
          chunk = string
          markdownProps = { ...markdownProps, ...attributes }
        } else if (chunk.startsWith('[') && chunk.endsWith(')')) {
          const linkRegex = /\[(.*?)\]\((.*?)(?:,\s*(.*?))?\)/;
          const match = chunk.match(linkRegex);
          const text = match[1];
          const url = match[2];
          const target = match[3] || "_blank";  // default to "_blank" if no target is provided


          let action = () => console.log(url)
          let linkObject = null

          try {
            linkObject = JSON.parse(url)
            if (linkObject) {
              action = () => onProcessLink(linkObject)
            }
          } catch (e) { }

          if (!linkObject) return (
            <Link
              key={index}
              href={url}
              isExternal={target === '_blank'}
            >
              <MarkdownText
                underline
                color="primary.400"
                top={isWeb ? undefined : "3px"}
                {...props}
                {...markdownProps}
              >
                {text || url}
              </MarkdownText>
            </Link>
          )

          return (
            <Pressable
              key={index}
              onPress={action}
            >
              <MarkdownText
                underline
                color="primary.400"
                top={isWeb ? undefined : "3px"}
                {...props}
                {...markdownProps}
              >
                {text}
              </MarkdownText>
            </Pressable>
          );
        }

        // chunk = chunk.trim()

        const internalChunks = chunk.split(regex);

        const RenderComponent = internalChunks.length === 1 ? Text : MarkdownText
        // const space = (index < chunks.length - 1 ? ' ' : '')

        return chunk === '' ? null : (
          <>
            <RenderComponent key={index} {...props} {...markdownProps} omitNextSpace>
              {chunk}
            </RenderComponent>
            {/* {(!omitNextSpace && space === ' ') ? <Text px="0.2px">{space}</Text> : null} */}
          </>
        );
      })}
    </Text>
  );
};

export default MarkdownText;
