import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

const IrcLowIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="56" height="56" viewBox="0 0 56 56"  {...props}>
      <G clip-path="url(#clip0_509_37811)">
        <Path
          d="M9.33337 7.46674V46.7041H27.2223L27.2576 39.834H32.6314L32.596 46.7041H37.5455L37.5808 34.8762H22.3435L22.3081 41.7463H14.2829V12.4245H41.7172V46.7041H46.6667V7.46674H9.33337Z"
          fill={fillColor}
        />
        <Path
          d="M18.9495 21.3131C18.9495 21.3131 18.8434 21.3131 18.8081 21.3839C18.8081 21.3839 18.7727 21.4548 18.7727 21.5256V30.3434C18.7727 30.3434 18.7727 30.4496 18.8081 30.485C18.8434 30.5204 18.8788 30.5204 18.9495 30.5204H21.0353C21.0353 30.5204 21.1414 30.5204 21.1767 30.485C21.1767 30.485 21.2121 30.4142 21.2121 30.3434V21.5256C21.2121 21.5256 21.2121 21.4194 21.1767 21.3839C21.1414 21.3485 21.106 21.3131 21.0353 21.3131H18.9495Z"
          fill={fillColor}
        />
        <Path
          d="M29.379 25.7752C29.6618 25.3502 29.8032 24.819 29.8032 24.2524C29.8032 23.6858 29.6618 23.1546 29.4144 22.7297C29.1669 22.3047 28.8133 21.9506 28.3537 21.7027C27.8942 21.4548 27.3638 21.3486 26.7982 21.3486H22.8032C22.8032 21.3486 22.6972 21.3486 22.6618 21.4194C22.6618 21.4194 22.6265 21.4902 22.6265 21.561V30.3788C22.6265 30.3788 22.6265 30.4851 22.6618 30.5205C22.6972 30.5559 22.7325 30.5559 22.8032 30.5559H24.8891C24.8891 30.5559 24.9952 30.5559 25.0305 30.5205C25.0305 30.5205 25.0659 30.4496 25.0659 30.3788V27.0854C25.0659 27.0854 25.0659 27.0146 25.1366 27.0146H25.7022C25.7022 27.0146 25.7729 27.0146 25.7729 27.05L27.2224 30.4142C27.2224 30.4142 27.3285 30.5559 27.4345 30.5559H29.6265C29.6265 30.5559 29.7325 30.5559 29.7679 30.5205C29.7679 30.5205 29.8032 30.4496 29.8032 30.4142C29.8032 30.4142 29.8032 30.3788 29.8032 30.308L28.1416 26.8375C28.1416 26.8375 28.1416 26.7667 28.177 26.7313C28.6719 26.5188 29.0608 26.2001 29.3436 25.7752H29.379ZM27.081 24.8898C27.081 24.8898 26.6921 25.1023 26.4093 25.1023H25.1719C25.1719 25.1023 25.1012 25.1023 25.1012 25.0315V23.5087C25.1012 23.5087 25.1012 23.4379 25.1719 23.4379H26.4093C26.6921 23.4379 26.9043 23.5087 27.081 23.6504C27.2578 23.792 27.3285 24.0045 27.3285 24.2524C27.3285 24.5003 27.2578 24.7128 27.081 24.8544V24.8898Z"
          fill={fillColor}
        />
        <Path
          d="M33.7273 23.6503C33.9394 23.4378 34.2222 23.3316 34.5757 23.3316C34.9293 23.3316 35.2121 23.4378 35.4242 23.6503C35.6363 23.8628 35.7424 24.1461 35.7424 24.5002C35.7424 24.6065 35.7778 24.6419 35.8838 24.6419H35.9192L38.005 24.5002C38.005 24.5002 38.2171 24.4294 38.2171 24.3586C38.2171 23.7212 38.0757 23.1545 37.7576 22.6942C37.4394 22.2338 37.0151 21.8443 36.4848 21.5964C35.9545 21.3485 35.3182 21.2068 34.5757 21.2068C33.8333 21.2068 33.2323 21.3485 32.6666 21.6318C32.101 21.9151 31.6767 22.3046 31.3939 22.8004C31.0757 23.2962 30.9343 23.8982 30.9343 24.5711V27.227C30.9343 27.8999 31.0757 28.5019 31.3939 28.9977C31.6767 29.4934 32.1363 29.9184 32.6666 30.2017C33.2323 30.485 33.8333 30.6266 34.5757 30.6266C35.3182 30.6266 35.9192 30.485 36.4848 30.2371C37.0505 29.9538 37.4747 29.5997 37.7576 29.1039C38.0404 28.6081 38.2171 28.0769 38.2171 27.4395C38.2171 27.3687 38.1464 27.2978 38.005 27.2978L35.9192 27.1916C35.9192 27.1916 35.8131 27.1916 35.7778 27.1916C35.7778 27.1916 35.7424 27.2624 35.7424 27.2978C35.7424 27.652 35.6363 27.9353 35.4242 28.1477C35.2121 28.3602 34.9293 28.4665 34.5757 28.4665C34.2222 28.4665 33.9394 28.3602 33.7273 28.1477C33.5151 27.9353 33.4091 27.652 33.4091 27.2978V24.4648C33.4091 24.1107 33.5151 23.8274 33.7273 23.6149V23.6503Z"
          fill={fillColor}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_509_37811">
          <Rect width="56" height="56" fill={fillColor} />
        </ClipPath>
      </Defs>
    </Icon>
  )
}
export default IrcLowIcon
