import React from 'react'
import { StyleSheet } from 'react-native'
import Icon from '../Icon'

import { HStack, Pressable, Text, View } from 'native-base'

const propertyTypes = { residential: 'residential', commercial: 'commercial' }

const PropertyTypeButton = ({
  isMobile,
  propertyType,
  pressed,
  handler,
  buttonStyle,
  textStyle,
  iconStyle,
  ...props
}) => (
  <Pressable onPress={() => handler(propertyType)} flex="1">
    <HStack
      style={[
        styles.button,
        pressed && styles.pressed,
        propertyType === propertyTypes.residential
          ? styles.buttonLeft
          : styles.buttonRight,
        isMobile && stylesMobile.button,
        isMobile && pressed && stylesMobile.pressed,
        buttonStyle && buttonStyle,
      ]}
      {...props}>
      <View mr="15px">
        <Icon
          size="6"
          style={[
            styles.icon,
            isMobile && stylesMobile.icon,
            iconStyle && iconStyle,
          ]}
          name={
            propertyType === propertyTypes.residential
              ? 'residentialProperty'
              : 'commercialProperty'
          }
          fillColor="#fff"
        />
      </View>
      <View>
        <Text
          style={[
            styles.text,
            isMobile && stylesMobile.text,
            textStyle && textStyle,
          ]}>
          {propertyType}
        </Text>
      </View>
    </HStack>
  </Pressable>
)

const PropertyTypeToggle = ({
  isMobile,
  selectedValue = 'residential',
  onToggle,
  buttonStyle,
  textStyle,
  iconStyle,
}) => (
  <View style={styles.mainContainer}>
    <HStack>
      <PropertyTypeButton
        propertyType={propertyTypes.residential}
        handler={onToggle}
        pressed={selectedValue === propertyTypes.residential}
        isMobile={isMobile}
        buttonStyle={buttonStyle}
        textStyle={textStyle}
        iconStyle={iconStyle}
        {...(selectedValue && { borderRightWidth: 1 })}
      />
      <PropertyTypeButton
        propertyType={propertyTypes.commercial}
        handler={onToggle}
        pressed={selectedValue === propertyTypes.commercial}
        isMobile={isMobile}
        buttonStyle={buttonStyle}
        textStyle={textStyle}
        iconStyle={iconStyle}
      />
    </HStack>
  </View>
)

export default PropertyTypeToggle

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 16,
  },
  button: {
    padding: 20,
    borderWidth: 1,
    borderColor: '#333440',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  buttonLeft: {
    borderTopEndRadius: 0,
    borderBottomEndRadius: 0,
  },
  buttonRight: {
    borderLeftWidth: 0,
    borderTopStartRadius: 0,
    borderBottomStartRadius: 0,
  },
  pressed: {
    backgroundColor: '#06264A',
    borderColor: '#207EED',
    borderBottomWidth: 4,
    paddingBottom: 16,
  },
  text: {
    fontSize: 20,
    fontWeight: '500',
    textTransform: 'capitalize',
  },
  icon: {
    width: 21,
    height: 21,
  },
})

const stylesMobile = StyleSheet.create({
  button: {
    padding: 14,
  },
  pressed: {
    paddingBottom: 11,
  },
  text: {
    fontSize: 16,
  },
  icon: {
    width: 20,
    height: 20,
  },
})
