import React from 'react'
import { HStack, VStack, Text, View, Pressable } from 'native-base'

import { StatusType, STATUS, STATUS_TITLE } from '../__Helpers/types'
import StatusIcon from '../__Helpers/StatusIcon'

export interface IAlertProps {
  title?: string | React.ReactNode
  message: string | React.ReactNode
  fontSize: string | number
  status: StatusType
  onPress?: () => void
}

const borderWidth = {
  [STATUS.success]: 1,
  [STATUS.error]: 1,
  [STATUS.info]: 0,
  [STATUS.addressInfoNotFoundNative]: 0,
}

const iconBackgorund = {
  [STATUS.success]: 'success.400',
  [STATUS.error]: 'error.600',
  [STATUS.info]: 'none',
  [STATUS.addressInfoNotFoundNative]: 'none',
}

const messageColor = {
  [STATUS.success]: 'success.400',
  [STATUS.error]: 'error.400',
  [STATUS.info]: 'text.50',
  [STATUS.addressInfoNotFoundNative]: 'text.50',
}

const radius = {
  [STATUS.success]: 10,
  [STATUS.error]: 10,
  [STATUS.info]: 0,
  [STATUS.addressInfoNotFoundNative]: 16,
}

const size = {
  [STATUS.success]: 2.5,
  [STATUS.error]: 2.5,
  [STATUS.info]: 5,
  [STATUS.addressInfoNotFoundNative]: 5,
}

const color = {
  [STATUS.success]: 'text.50',
  [STATUS.error]: 'text.50',
  [STATUS.info]: 'cyan.300',
  [STATUS.addressInfoNotFoundNative]: 'cyan.300',
}

const background = {
  [STATUS.success]: 'background.700',
  [STATUS.error]: 'background.700',
  [STATUS.info]: 'primary.800',
  [STATUS.addressInfoNotFoundNative]: 'background.600',
}

const AlertElements = ({ title, message, status, fontSize }: IAlertProps) => (
  <>
    <StatusIcon
      status={status}
      color={color[status]}
      borderRadius={radius[status]}
      w="5"
      h="5"
      _web={{ size: '3' }}
      size={size[status]}
      bg={iconBackgorund[status]}
    />
    <VStack space="2" flex="1" pl="2">
      {title && (
        <Text fontSize={fontSize || 'sm'} fontWeight="600">
          {title || STATUS_TITLE[status] || STATUS_TITLE.info}
        </Text>
      )}
      {message && (
        <Text
          fontSize={fontSize || 'sm'}
          color={messageColor[status]}
          fontWeight="400">
          {message}
        </Text>
      )}
    </VStack>
  </>
)
const Alert = (props: IAlertProps) => {
  const { status, onPress } = props
  return (
    <HStack
      p={4}
      borderWidth={borderWidth[status]}
      borderColor={`${status}.400`}
      borderRadius={10}
      bg={background[status]}
      space={2}>
      {onPress ? (
        <Pressable onPress={onPress} w="100%" flexDirection="row">
          <AlertElements {...props} />
        </Pressable>
      ) : (
        <AlertElements {...props} />
      )}
    </HStack>
  )
}

export default Alert
