import { useEffect, useState } from 'react'
import { Box, Pressable, Stack } from 'native-base'

import Icon, { IIconPropsWithAntDesign } from '../../../components/Icon'

type IIconPropsWithoutName = Omit<IIconPropsWithAntDesign, 'name'>

export interface IHoverIconProps extends IIconPropsWithoutName {
  initial: IIconPropsWithAntDesign
  hovered: IIconPropsWithAntDesign
  onPress?: () => void
  disabled?: boolean
}

const HoverIcon = (props: IHoverIconProps) => {
  const { initial, hovered, onPress, disabled, ...rest } = props

  return (
    <Pressable onPress={onPress} disabled={disabled}>
      {childProps => {
        const { isHovered } = childProps

        return (
          // wrap in a Box to prevent hover events from propagating to the Icon which was causing the isHovered to not show correctly
          <Box pointerEvents="none">
            <Icon {...rest} {...(isHovered ? hovered : initial)} />
          </Box>
        )
      }}
    </Pressable>
  )
}

export interface IIconRadioGroupOptionsProps extends IHoverIconProps {
  value: string
  onPress?: () => void
}

export interface IIconRadioGroupProps {
  options?: Array<IIconRadioGroupOptionsProps>
  initialValue?: string
  onPress?: (option: IIconRadioGroupOptionsProps) => void
}

const IconRadioGroup = (props: IIconRadioGroupProps) => {
  const { options = [], onPress = () => null, initialValue = null } = props
  const [selected, setSelected] = useState(initialValue)

  useEffect(() => {
    if (initialValue !== selected) {
      setSelected(initialValue)
    }
  }, [initialValue])

  return (
    <Stack direction="row" space="4">
      {options.map((item: IIconRadioGroupOptionsProps) => {
        const { value, initial, hovered, ...rest } = item

        return (
          <HoverIcon
            key={value}
            initial={selected === value ? hovered : initial}
            hovered={hovered}
            {...rest}
            onPress={() => {
              setSelected(value)
              if (onPress) onPress(item)
            }}
          />
        )
      })}
    </Stack>
  )
}

export default IconRadioGroup
