
export const NATIVE_ICONS_NAME_MAP: Record<string, string> = {
  accountbook: 'account-book',
  aim: 'aim',
  alert: 'alert',
  alibaba: 'alibaba',
  aligncenter: 'align-center',
  alignleft: 'align-left',
  alignright: 'align-right',
  alipaycircle: 'alipay-circle',
  alipay: 'alipay',
  aliwangwang: 'aliwangwang',
  aliyun: 'aliyun',
  amazon: 'amazon',
  android: 'android',
  antcloud: 'ant-cloud',
  antdesign: 'ant-design',
  apartment: 'apartment',
  api: 'api',
  apple: 'apple',
  appstoreadd: 'appstore-add',
  appstore: 'appstore',
  areachart: 'area-chart',
  arrowdown: 'arrow-down',
  arrowleft: 'arrow-left',
  arrowright: 'arrow-right',
  arrowup: 'arrow-up',
  arrowsalt: 'arrows-alt',
  audiomuted: 'audio-muted',
  audio: 'audio',
  audit: 'audit',
  backward: 'backward',
  bank: 'bank',
  barchart: 'bar-chart',
  barcode: 'barcode',
  bars: 'bars',
  behancesquare: 'behance-square',
  behance: 'behance',
  bell: 'bell',
  bgcolors: 'bg-colors',
  block: 'block',
  bold: 'bold',
  book: 'book',
  borderbottom: 'border-bottom',
  borderhorizontal: 'border-horizontal',
  borderinner: 'border-inner',
  borderleft: 'border-left',
  borderouter: 'border-outer',
  borderright: 'border-right',
  bordertop: 'border-top',
  borderverticle: 'border-verticle',
  border: 'border',
  borderlesstable: 'borderless-table',
  boxplot: 'box-plot',
  branches: 'branches',
  bug: 'bug',
  build: 'build',
  bulb: 'bulb',
  calculator: 'calculator',
  calendar: 'calendar',
  camera: 'camera',
  car: 'car',
  caretdown: 'caret-down',
  caretleft: 'caret-left',
  caretright: 'caret-right',
  caretup: 'caret-up',
  carryout: 'carry-out',
  checkcircle: 'check-circle',
  checksquare: 'check-square',
  check: 'check',
  chrome: 'chrome',
  cicircle: 'ci-circle',
  ci: 'ci',
  clear: 'clear',
  clockcircle: 'clock-circle',
  closecircle: 'close-circle',
  closesquare: 'close-square',
  close: 'close',
  clouddownload: 'cloud-download',
  cloudserver: 'cloud-server',
  cloudsync: 'cloud-sync',
  cloudupload: 'cloud-upload',
  cloud: 'cloud',
  cluster: 'cluster',
  codesandbox: 'code-sandbox',
  code: 'code',
  codepencircle: 'codepen-circle',
  codepen: 'codepen',
  coffee: 'coffee',
  columnheight: 'column-height',
  columnwidth: 'column-width',
  comment: 'comment',
  compass: 'compass',
  compress: 'compress',
  consolesql: 'console-sql',
  contacts: 'contacts',
  container: 'container',
  control: 'control',
  copy: 'copy',
  copyrightcircle: 'copyright-circle',
  copyright: 'copyright',
  creditcard: 'credit-card',
  crown: 'crown',
  customerservice: 'customer-service',
  dash: 'dash',
  dashboard: 'dashboard',
  database: 'database',
  deletecolumn: 'delete-column',
  deleterow: 'delete-row',
  delete: 'delete',
  deliveredprocedure: 'delivered-procedure',
  deploymentunit: 'deployment-unit',
  desktop: 'desktop',
  diff: 'diff',
  dingding: 'dingding',
  dingtalk: 'dingtalk',
  disconnect: 'disconnect',
  dislike: 'dislike',
  dollarcircle: 'dollar-circle',
  dollar: 'dollar',
  dotchart: 'dot-chart',
  doubleleft: 'double-left',
  doubleright: 'double-right',
  downcircle: 'down-circle',
  downsquare: 'down-square',
  down: 'down',
  download: 'download',
  drag: 'drag',
  dribbblesquare: 'dribbble-square',
  dribbble: 'dribbble',
  dropbox: 'dropbox',
  edit: 'edit',
  ellipsis: 'ellipsis',
  enter: 'enter',
  environment: 'environment',
  eurocircle: 'euro-circle',
  euro: 'euro',
  exception: 'exception',
  exclamationcircle: 'exclamation-circle',
  exclamation: 'exclamation',
  expandalt: 'expand-alt',
  expand: 'expand',
  experiment: 'experiment',
  export: 'export',
  eyeinvisible: 'eye-invisible',
  eye: 'eye',
  facebook: 'facebook',
  fall: 'fall',
  fastbackward: 'fast-backward',
  fastforward: 'fast-forward',
  fieldbinary: 'field-binary',
  fieldnumber: 'field-number',
  fieldstring: 'field-string',
  fieldtime: 'field-time',
  fileadd: 'file-add',
  filedone: 'file-done',
  fileexcel: 'file-excel',
  fileexclamation: 'file-exclamation',
  filegif: 'file-gif',
  fileimage: 'file-image',
  filejpg: 'file-jpg',
  filemarkdown: 'file-markdown',
  filepdf: 'file-pdf',
  fileppt: 'file-ppt',
  fileprotect: 'file-protect',
  filesearch: 'file-search',
  filesync: 'file-sync',
  filetext: 'file-text',
  fileunknown: 'file-unknown',
  fileword: 'file-word',
  filezip: 'file-zip',
  file: 'file',
  filter: 'filter',
  fire: 'fire',
  flag: 'flag',
  folderadd: 'folder-add',
  folderopen: 'folder-open',
  folderview: 'folder-view',
  folder: 'folder',
  fontcolors: 'font-colors',
  fontsize: 'font-size',
  fork: 'fork',
  form: 'form',
  formatpainter: 'format-painter',
  forward: 'forward',
  frown: 'frown',
  fullscreenexit: 'fullscreen-exit',
  fullscreen: 'fullscreen',
  function: 'function',
  fundprojectionscreen: 'fund-projection-screen',
  fundview: 'fund-view',
  fund: 'fund',
  funnelplot: 'funnel-plot',
  gateway: 'gateway',
  gif: 'gif',
  gift: 'gift',
  github: 'github',
  gitlab: 'gitlab',
  global: 'global',
  gold: 'gold',
  googleplus: 'google-plus',
  google: 'google',
  group: 'group',
  hdd: 'hdd',
  heart: 'heart',
  heatmap: 'heat-map',
  highlight: 'highlight',
  history: 'history',
  holder: 'holder',
  home: 'home',
  hourglass: 'hourglass',
  html5: 'html5',
  idcard: 'idcard',
  ie: 'ie',
  import: 'import',
  inbox: 'inbox',
  infocircle: 'info-circle',
  info: 'info',
  insertrowabove: 'insert-row-above',
  insertrowbelow: 'insert-row-below',
  insertrowleft: 'insert-row-left',
  insertrowright: 'insert-row-right',
  instagram: 'instagram',
  insurance: 'insurance',
  interaction: 'interaction',
  issuesclose: 'issues-close',
  italic: 'italic',
  key: 'key',
  laptop: 'laptop',
  layout: 'layout',
  leftcircle: 'left-circle',
  leftsquare: 'left-square',
  left: 'left',
  like: 'like',
  linechart: 'line-chart',
  lineheight: 'line-height',
  line: 'line',
  link: 'link',
  linkedin: 'linkedin',
  loading3quarters: 'loading-3-quarters',
  loading: 'loading',
  lock: 'lock',
  login: 'login',
  logout: 'logout',
  maccommand: 'mac-command',
  mail: 'mail',
  man: 'man',
  medicinebox: 'medicine-box',
  mediumworkmark: 'medium-workmark',
  medium: 'medium',
  meh: 'meh',
  menufold: 'menu-fold',
  menuunfold: 'menu-unfold',
  menu: 'menu',
  mergecells: 'merge-cells',
  message: 'message',
  minuscircle: 'minus-circle',
  minussquare: 'minus-square',
  minus: 'minus',
  mobile: 'mobile',
  moneycollect: 'money-collect',
  monitor: 'monitor',
  more: 'more',
  nodecollapse: 'node-collapse',
  nodeexpand: 'node-expand',
  nodeindex: 'node-index',
  notification: 'notification',
  number: 'number',
  onetoone: 'one-to-one',
  orderedlist: 'ordered-list',
  paperclip: 'paper-clip',
  partition: 'partition',
  pausecircle: 'pause-circle',
  pause: 'pause',
  paycircle: 'pay-circle',
  percentage: 'percentage',
  phone: 'phone',
  piccenter: 'pic-center',
  picleft: 'pic-left',
  picright: 'pic-right',
  picture: 'picture',
  piechart: 'pie-chart',
  playcircle: 'play-circle',
  playsquare: 'play-square',
  pluscircle: 'plus-circle',
  plussquare: 'plus-square',
  plus: 'plus',
  poundcircle: 'pound-circle',
  pound: 'pound',
  poweroff: 'poweroff',
  printer: 'printer',
  profile: 'profile',
  project: 'project',
  propertysafety: 'property-safety',
  pullrequest: 'pull-request',
  pushpin: 'pushpin',
  qq: 'qq',
  qrcode: 'qrcode',
  questioncircle: 'question-circle',
  question: 'question',
  radarchart: 'radar-chart',
  radiusbottomleft: 'radius-bottomleft',
  radiusbottomright: 'radius-bottomright',
  radiussetting: 'radius-setting',
  radiusupleft: 'radius-upleft',
  radiusupright: 'radius-upright',
  read: 'read',
  reconciliation: 'reconciliation',
  redenvelope: 'red-envelope',
  reddit: 'reddit',
  redo: 'redo',
  reload: 'reload',
  rest: 'rest',
  retweet: 'retweet',
  rightcircle: 'right-circle',
  rightsquare: 'right-square',
  right: 'right',
  rise: 'rise',
  robot: 'robot',
  rocket: 'rocket',
  rollback: 'rollback',
  rotateleft: 'rotate-left',
  rotateright: 'rotate-right',
  safetycertificate: 'safety-certificate',
  safety: 'safety',
  save: 'save',
  scan: 'scan',
  schedule: 'schedule',
  scissor: 'scissor',
  search: 'search',
  securityscan: 'security-scan',
  select: 'select',
  send: 'send',
  setting: 'setting',
  shake: 'shake',
  sharealt: 'share-alt',
  shop: 'shop',
  shoppingcart: 'shopping-cart',
  shopping: 'shopping',
  shrink: 'shrink',
  sisternode: 'sisternode',
  sketch: 'sketch',
  skin: 'skin',
  skype: 'skype',
  slacksquare: 'slack-square',
  slack: 'slack',
  sliders: 'sliders',
  smalldash: 'small-dash',
  smile: 'smile',
  snippets: 'snippets',
  solution: 'solution',
  sortascending: 'sort-ascending',
  sortdescending: 'sort-descending',
  sound: 'sound',
  splitcells: 'split-cells',
  star: 'star',
  stepbackward: 'step-backward',
  stepforward: 'step-forward',
  stock: 'stock',
  stop: 'stop',
  strikethrough: 'strikethrough',
  subnode: 'subnode',
  swapleft: 'swap-left',
  swapright: 'swap-right',
  swap: 'swap',
  switcher: 'switcher',
  sync: 'sync',
  table: 'table',
  tablet: 'tablet',
  tag: 'tag',
  tags: 'tags',
  taobaocircle: 'taobao-circle',
  taobao: 'taobao',
  team: 'team',
  thunderbolt: 'thunderbolt',
  totop: 'to-top',
  tool: 'tool',
  trademarkcircle: 'trademark-circle',
  trademark: 'trademark',
  transaction: 'transaction',
  translation: 'translation',
  trophy: 'trophy',
  twitter: 'twitter',
  underline: 'underline',
  undo: 'undo',
  ungroup: 'ungroup',
  unlock: 'unlock',
  unorderedlist: 'unordered-list',
  upcircle: 'up-circle',
  upsquare: 'up-square',
  up: 'up',
  upload: 'upload',
  usb: 'usb',
  useradd: 'user-add',
  userdelete: 'user-delete',
  userswitch: 'user-switch',
  user: 'user',
  usergroupadd: 'usergroup-add',
  usergroupdelete: 'usergroup-delete',
  verified: 'verified',
  verticalalignbottom: 'vertical-align-bottom',
  verticalalignmiddle: 'vertical-align-middle',
  verticalaligntop: 'vertical-align-top',
  verticalleft: 'vertical-left',
  verticalright: 'vertical-right',
  videocameraadd: 'video-camera-add',
  videocamera: 'video-camera',
  wallet: 'wallet',
  warning: 'warning',
  wechat: 'wechat',
  weibocircle: 'weibo-circle',
  weibosquare: 'weibo-square',
  weibo: 'weibo',
  whatsapp: 'whats-app',
  wifi: 'wifi',
  windows: 'windows',
  woman: 'woman',
  yahoo: 'yahoo',
  youtube: 'youtube',
  yuque: 'yuque',
  zhihu: 'zhihu',
  zoomin: 'zoom-in',
  zoomout: 'zoom-out',
  alipaysquare: 'alipay-square',
  amazoncircle: 'amazon-circle',
  amazonsquare: 'amazon-square',
  behancecircle: 'behance-circle',
  codesandboxcircle: 'code-sandbox-circle',
  codesandboxsquare: 'code-sandbox-square',
  codepensquare: 'codepen-square',
  dingtalkcircle: 'dingtalk-circle',
  dingtalksquare: 'dingtalk-square',
  dribbblecircle: 'dribbble-circle',
  dropboxcircle: 'dropbox-circle',
  dropboxsquare: 'dropbox-square',
  golden: 'golden',
  googlecircle: 'google-circle',
  googlepluscircle: 'google-plus-circle',
  googleplussquare: 'google-plus-square',
  googlesquare: 'google-square',
  iecircle: 'ie-circle',
  iesquare: 'ie-square',
  mediumcircle: 'medium-circle',
  mediumsquare: 'medium-square',
  qqcircle: 'qq-circle',
  qqsquare: 'qq-square',
  redditcircle: 'reddit-circle',
  redditsquare: 'reddit-square',
  signal: 'signal',
  sketchcircle: 'sketch-circle',
  sketchsquare: 'sketch-square',
  slackcircle: 'slack-circle',
  taobaosquare: 'taobao-square',
  twittercircle: 'twitter-circle',
  twittersquare: 'twitter-square',
  zhihucircle: 'zhihu-circle',
  zhihusquare: 'zhihu-square',
}
