export default {
  darkTheme: {
    primary: '#64ABFF',
    secondary: '#1EA7FD',
    purple: '#9588e2',
    // UI
    appBg: '#121320',
    appBrandGold: '#FFB829',
    appSecondaryBg: '#2f2f2f',
    appContentBg: '#121320',
    appContentSecondaryBg: '#333333',
    appBorder: 'gray',
    // Text colors
    text: 'black',
    error: '#B83E26',
    textInverse: 'rgba(255,255,255,0.9)',
    primaryText: '#FF4785',
    secondaryText: '#1EA7FD',
    textMuted: 'gray',
    // Toolbar default and active colors
    barText: 'silver',
    barSelected: 'black',
    barBg: 'gray',

    // Form colors
    input: 'white',
    inputBg: '#333440',
    label: 'white',
    inputBorder: 'silver',
    inputText: 'white',
    inputDisabled: 'gray',
    inputDisabledBg: 'gray',
    inputSelection: 'blue',
    // Button colors
    primaryButton: 'blue',
    secondaryButton: 'red',
    dangerButton: 'orange',
    linkButton: 'blue',
  },
  lightTheme: {
    primary: '#FF4785',
    secondary: '#1EA7FD',
    // UI
    appBg: 'white',
    appContentBg: 'silver',
    appBorder: 'gray',
    appBrandGold: '#FFB829',
    appSecondaryBg: 'gray',
    appContentSecondaryBg: '#333333',
    // Text colors
    text: 'black',
    error: '#B83E26',
    textInverse: 'rgba(255,255,255,0.9)',
    primaryText: '',
    secondaryText: '',

    // Toolbar default and active colors
    barText: 'silver',
    barSelected: 'black',
    barBg: 'hotpink',

    // Form colors
    inputBg: '#333440',
    inputBorder: 'silver',
    inputText: 'black',
    label: 'white',
    // Button colors
    primaryButton: '',
    secondaryButton: '',
    dangerButton: '',
    linkButton: '',
  },
}
