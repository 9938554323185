import React from 'react'
import {
  Text,
  VStack,
  HStack,
  Divider,
  View,
  IBoxProps,
  Box,
  ITextProps,
} from 'native-base'
import Icon from '../../Icon'
import { IconName } from '../../Icon/types.icon'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'
export interface NewPlanBannerProps {
  icon?: {
    name: IconName
    size?: string
  }
  title: string
  titleProps?: ITextProps
  subtitle?: string
  subtitleProps?: ITextProps
  noBackground?: boolean
  price?: string
  priceFrequency?: string
  isDisabled?: boolean
  rightSubtitle?: string
  rightSubtitleProps?: ITextProps
  RightSubtitleElement?: () => JSX.Element
  RightElement?: () => JSX.Element
  subtitleContainerProps?: IViewProps
  mainStackContainerProps?: IViewProps
  iconWrapper?: IViewProps
}

const defaultSubtitlesStyle = {
  color: 'text.200',
  fontSize: '2xs',
  fontWeight: '500',
}

const defaultTitleProps = {
  fontSize: 'lg',
  fontWeight: '500',
}

const NewPlanBanner = ({
  icon,
  title,
  subtitle,
  subtitleProps,
  price,
  titleProps,
  rightSubtitle,
  rightSubtitleProps,
  RightSubtitleElement,
  priceFrequency,
  RightElement,
  noBackground,
  subtitleContainerProps = {},
  mainStackContainerProps = {},
  iconWrapper = {},
}: NewPlanBannerProps) => {
  // alignment depends on 4 variants:
  const startTitlesAlign =
    (rightSubtitle || RightSubtitleElement || subtitle) && !RightElement
      ? 'baseline'
      : 'center'
  const alignRightElements = RightElement ? { alignItems: 'center' } : {}

  const alignSubtitles =
    RightElement && (subtitle || rightSubtitle)
      ? {
          _web: { height: '100%' },
          justifyContent: 'center',
          alignItems: 'stretch',
        }
      : {}

  const containerProps = noBackground
    ? {}
    : { bg: 'background.700', borderRadius: 16 }

  return (
    <Box w="100%" {...containerProps} p={4}>
      <HStack
        justifyContent="flex-start"
        _web={{ justifyContent: 'start' }}
        alignItems={startTitlesAlign}
        w="100%"
        {...mainStackContainerProps}>
        {icon && (
          <View mr={2} alignSelf="center" {...iconWrapper}>
            <Icon name={icon.name} size={icon?.size || 'xl'} />
          </View>
        )}
        <View {...alignSubtitles} {...subtitleContainerProps}>
          <Text {...defaultTitleProps} {...titleProps}>
            {title}
          </Text>
          {subtitle && (
            <Text {...{ ...defaultSubtitlesStyle, ...subtitleProps }}>
              {subtitle}
            </Text>
          )}
        </View>
        <View
          ml="auto"
          alignItems="flex-end"
          alignContent="center"
          alignSelf="center"
          {...alignRightElements}>
          {RightElement && <RightElement />}
          <Text color="primary.200" fontStyle="italic" fontSize="lg">
            {price}
            {priceFrequency && priceFrequency}
          </Text>
          {RightSubtitleElement && <RightSubtitleElement />}
          {rightSubtitle && !RightSubtitleElement && (
            <Text {...{ ...defaultSubtitlesStyle, ...rightSubtitleProps }}>
              {rightSubtitle}
            </Text>
          )}
        </View>
      </HStack>
    </Box>
  )
}

export default NewPlanBanner
