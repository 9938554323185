import { Platform } from 'react-native'
import {
  ButtonThemeDefinition,
  InputThemeDefinition,
  SelectThemeDefinition,
} from './Components'

export const webFontSize = {
  '2xs': 12,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 28,
  '4xl': 32,
  '5xl': 36,
  '6xl': 40,
  '7xl': 46,
  '8xl': 52,
  '9xl': 58,
  '10xl': 64,
}

export const nativeFontSize = {
  '2xs': 12,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 28,
  '4xl': 32,
  '5xl': 36,
  '6xl': 40,
  '7xl': 46,
  '8xl': 52,
  '9xl': 58,
  '10xl': 64,
}

const fontSizes = Platform.OS === 'web' ? webFontSize : nativeFontSize

const fontFamily = 'Barlow'

export default (palette) => {
  const breakpoints = {
    base: 0,
    sm: 480,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 2560,
  }

  const components = {
    Modal: {
      defaultProps: {
        overlayVisible: true,
        backdropVisible: true,
        closeOnOverlayClick: false,
        _backdrop: { bg: 'background.600', opacity: 0.65 },
      },
    },
    KeyboardAvoidingView: {
      baseStyle: {
        flex: 1,
      },
      defaultProps: {
        bg: 'background.800',
        _ios: {
          keyboardVerticalOffset: 80,
          behavior: 'padding',
        },
      },
      variants: {},
      sizes: {},
    },
    // View: {
    // bg: 'background.800',
    Text: {
      baseStyle: {
        padding: 0,
        margin: 0,
        _disabled: {
          color: 'text.500',
        },
      },
      defaultProps: {
        fontSize: 'md',
        fontFamily,
        fontVariant: 'normal',
        color: 'text.50',
      },
      variants: {},
      sizes: {},
    },
    Heading: {
      baseStyle: {},
      defaultProps: {
        fontFamily,
        color: 'text.50',
        lineHeight: '1.5em',
      },
      variants: {},
      sizes: {
        '2xs': {
          fontSize: 'xs',
          pb: 4,
          fontWeight: 'bold',
        },
        xs: {
          fontSize: 'xs',
          pb: 4,
          fontWeight: 'bold',
        },
        sm: {
          fontSize: 'sm',
          pb: 4,
          fontWeight: 'bold',
        },
        md: {
          fontSize: 'md',
          pb: 4,
          fontWeight: 'bold',
        },
        lg: {
          fontSize: 'lg',
          pb: 4,
          fontWeight: 'bold',
        },
        xl: {
          fontSize: 'xl',
          pb: 4,
          fontWeight: 'bold',
        },
        '2xl': {
          fontSize: '2xl',
          pb: 4,
          fontWeight: 'bold',
        },
        '3xl': {
          fontSize: '3xl',
          pb: 4,
          fontWeight: 'bold',
        },
        '4xl': {
          fontSize: '4xl',
          pb: 4,
          fontWeight: 'bold',
        },
        '5xl': {
          fontSize: '5xl',

          pb: 6,
          fontWeight: 'medium',
        },
        '6xl': {
          fontSize: '6xl',

          pb: 6,
          fontWeight: 'medium',
        },
        '7xl': {
          fontSize: '7xl',

          pb: 6,
          fontWeight: 'medium',
        },
        '8xl': {
          fontSize: '8xl',

          pb: 8,
          fontWeight: 'regular',
        },
        '9xl': {
          fontSize: '9xl',

          pb: 8,
          fontWeight: 'regular',
        },
      },
    },
    Select: SelectThemeDefinition,
    Tooltip: {
      baseStyle: {},
      defaultProps: {
        _text: { fontSize: 'sm' },
      },
      variants: {},
      sizes: {},
    },
    Input: InputThemeDefinition,
    TextArea: {
      baseStyle: {
        p: 2,
        borderRadius: 4,
        paddingTop: 0,
        paddingBottom: 1.5,
        paddingHorizontal: 0,
        margin: 0,
        color: palette.input,
        paddingLeft: 0,
        _disabled: {
          opacity: 1,
          bg: palette.inputDisabledBg,
          color: palette.inputDisabled,
        },
        _text: {
          color: 'text.50',
        },
      },
      defaultProps: {
        selectionColor: 'text.50',
        isFullWidth: true,
        variant: 'unstyled',
        color: 'text.50',
        fontSize: 'md',
        bg: 'background.600',
        autoCapitalize: 'none',
        _focusVisible: {},
      },
      variants: {},
      sizes: {},
    },
    Divider: {
      baseStyle: {},
      defaultProps: {
        bg: 'background.600',
      },
      variants: {},
      sizes: {},
    },
    FormControlLabel: {
      baseStyle: {
        opacity: 1,
        _text: {
          fontFamily,
          fontWeight: '700',
          letterSpacing: 1.2,
          fontSize: 12,
        },
      },
      defaultProps: {},
      variants: {},
      sizes: {},
    },
    Button: ButtonThemeDefinition,
    Avatar: {
      baseStyle: {
        backgroundColor: palette.appContentSecondaryBg,
        marginBottom: 2,
        _text: {
          color: palette.primaryText,
          fontSize: 16,
        },
      },
      defaultProps: {},
      variants: {},
      sizes: {},
    },
  }

  const fontConfig = {
    Barlow: {
      400: {
        normal: fontFamily,
      },
      500: {
        normal: fontFamily,
      },
      600: {
        normal: fontFamily,
      },
      700: {
        normal: fontFamily,
      },
      800: {
        normal: fontFamily,
      },
      900: {
        normal: fontFamily,
      },
    },
  }

  const fonts = {
    heading: 'Barlow',
    body: 'Barlow',
    mono: 'Barlow',
  }

  const colors = {
    primary: {
      900: '#121320', // backgroundSite
      800: '#06264A',
      700: '#0C57A9',
      600: '#0564A2', //cyan700
      500: '#207EED', // cyan500
      400: '#1AA7FF', // cyan500
      300: '#6EC7FF',
      200: '#ABDFFF', // cyan200
      100: '#D5EFFF',
      50: '#EAF7FE',
    },
    secondary: {
      900: '#130C00',
      800: '#3C2801',
      700: '#795103',
      600: '#C98702',
      500: '#F0A104',
      400: '#FCB627', // amber500
      300: '#FDC75E',
      200: '#FCD586',
      100: '#FDEAC1',
      50: '#FFF8EB',
    },
    purple: {
      500: '#9588e2',
    },
    error: {
      900: '#140100',
      800: '#3C0400',
      700: '#660800',
      600: '#A20E00',
      500: '#CC0F00',
      400: '#FF4D3D', 
      300: '#FE685B',
      200: '#FEA298',
      100: '#FED9D6',
      50: '#FEFEFE',
    },
    celebrate: {
      900: '#011300',
      800: '#073D01',
      700: '#0A6500',
      600: '#0E7900',
      500: '#11A200',
      400: '#15B300',
      300: '#1AE001',
      200: '#5BFE47',
      100: '#A3FE98',
      50: '#ECFEEB',
    },
    success: {
      900: '#011300',
      800: '#073D01',
      700: '#0A6500',
      600: '#0E7900',
      500: '#11A200',
      400: '#15B300',
      300: '#1AE001',
      200: '#5BFE47',
      100: '#A3FE98',
      50: '#ECFEEB',
    },
    text: {
      900: '#0A0A0A',
      700: '#353535',
      500: '#888888', // neutral600
      // 400: '#A4A4B7',
      // 300: '#B9BCC9',
      200: '#AFAFAF', // neutral500
      // 100: '#DCDCE4',
      50: '#FFFFFF',
    },
    background: {
      // 900: '#0A0A0A',
      // keeping around for refactor removal during refactor
      800: '#121320', // backgroundSite
      700: '#191A27', // boxes
      600: '#333340', // backgroundInput
      500: '#333440', // input

      // https://www.figma.com/file/bVDocwr1NUTVs1naSTplZQ/4P-Styles?node-id=4%3A966&mode=dev
      site: '#121320',
      boxes: '#191A27', // matching style guide here
      input: '#333440', // matching style guide here
      hover: '#242538',
      // 500: '#888888', // neutral600
      // 400: '#A4A4B7',
      // 300: '#B9BCC9',
      // 200: '#AFAFAF',
      // 100: '#DCDCE4',
      // 50: '#F5F5F5',
    },
    uncategorized: {
      900: '#8581B0',
    },
    neutral: {
      '00': '#FFFFFF',
      300: '#D5D8D7',
      400: '#C9CFCF',
      500: '#A1B0B7',
      600: '#788389',
      700: '#4A5459',
      900: '#141414',
    },
    amber: {
      400: '#FFBD59',
      500: '#FEAC01',
      600: '#D48F00',
      700: '#D48F00',
    },
    cyan: {
      200: '#91C0F6',
      300: '#6CAAF3',
      400: '#4694F0',
      500: '#207EED',
      600: '#1063C5',
      800: '#082F5D',
      50: '#CAE0FB',
    },
  }

  const theme = {
    breakpoints,
    components,
    fontSizes,
    fontConfig,
    fonts,
    colors,
  }

  return theme
}
