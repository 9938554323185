import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Actionsheet, Box, Button, Hidden, IButtonProps, Modal as NativeBaseModal, ScrollView, Spinner, Stack, useDisclose } from 'native-base'
import _ from 'lodash'
import { IModalProps as INativeBaseModalProps } from 'native-base/lib/typescript/components/composites/Modal'
import { ResponsiveValue } from 'native-base/lib/typescript/components/types'

import { Dimensions, isWeb, useIsMounted } from '../../../utils'
import MarkdownText from '../../../components/PresentationalComponents/MarkdownText'

const MODAL_BORDER_RADIUS = 16 // in px
const ACTIONSHEET_BORDER_RADIUS = 8 // in px
const MODAL_BACKGROUND_COLOR = "background.700"

const onLayout = () => {
  try {
    if (isWeb) {
      const overlayDiv = document.querySelector(
        '#root > div > div:nth-child(2)',
      ) as HTMLDivElement
      overlayDiv.style.zIndex = '1'
      overlayDiv.style.position = 'fixed'
      overlayDiv.style.height = '100vh'
      overlayDiv.style.width = '100vw'
    }
  } catch (e) {
    console.log('Failed on layout:', e)
  }
}

const Loader = () => (
  <Box
    w="100%"
    bg="background.700"
    justifyContent="center"
    h="300"
    alignItems="center">
    <Spinner color="text.50" />
  </Box>
)

interface IWrapperProps {
  isLoading?: boolean
}

type IButtonAction = (() => void) | (() => Promise<void>)
type IButtonDirection = ResponsiveValue<'column' | 'row' | 'column-reverse' | 'row-reverse'>

const LocalButton = (props: IButtonProps) => <Button
  height={{ base: '48px' }}
  // width={{ base: "unset": md }}
  padding={{ base: "0", }}
  px={{ base: "6" }}
  size={{ base: 'md' }}
  {...props}
/>

export interface IModalProps extends INativeBaseModalProps {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  Header?: React.ReactNode
  primaryAction?: IButtonAction
  primaryActionText?: string
  secondaryAction?: IButtonAction
  secondaryActionText?: string
  primaryActionProps?: IButtonProps
  secondaryActionProps?: IButtonProps
  webSizing?: "sm" | "md" | "xl"
  nativeHeight?: "auto" | "tall"
  withLoading?: boolean
  dismissible?: boolean
  invisible?: boolean
  withClose?: boolean
  buttonsDirection?: IButtonDirection
  contentProps: any
  bodyProps: any
  footerProps: any
  headerProps: any
}

const DEFAULT_PRIMARY_ACTION_PROPS: IButtonProps = {
  w: { base: '100%', md: 'auto' },
  h: 'auto',
  // maxW: 300,
  _text: {
    fontSize: 'md',
    fontWeight: 'medium',
    flexWrap: 'wrap',
    textAlign: 'center',
    maxW: 300,
  },
}

const DEFAULT_SECONDARY_ACTION_PROPS: IButtonProps = {
  w: { base: '100%', md: 'auto' },
  h: 'auto',
  // maxW: 300,
  _text: {
    fontSize: 'md',
    fontWeight: 'medium',
    flexWrap: 'wrap',
    textAlign: 'center',
    maxW: 300,
  },
  variant: 'link',
}

const MODAL_WEB_SIZE_MAP = {
  "sm": {
    maxW: {
      base: '300',
      sm: '350',
    },
  },
  "md": {
    width: {
      base: 'unset',
    },
    mx: {
      base: 4
    },
    maxW: {
      base: '100%',
      sm: '350',
      md: '480',
    },
  },
  "xl": {
    maxW: {
      base: '300',
      sm: '350',
      lg: '580'
    },
  },
}

interface IModalResult {
  result: 'primary' | 'secondary' | 'dismissed';
  data?: any;  // Replace `any` with the actual type if known
}
interface IModalContext {
  showModal: (props: IModalProps) => Promise<IModalResult>
}

const ModalContext = createContext<IModalContext>({
  showModal: () => new Promise(resolve => resolve({ result: 'dismissed' }))
})

export const ModalContextProvider = (props: any) => {
  const { children } = props

  const { isOpen, onClose, onOpen } = useDisclose()
  const [modalProps, setModalProps] = useState()

  const resolveModalResults = useRef((args: unknown): Promise<IModalResult> => { return new Promise(r => r({ result: 'dismissed' })) })

  const showModal = useCallback((options = {}): Promise<IModalResult> => {
    return new Promise((resolve: any) => {
      const localOptions = { ...options }
      resolveModalResults.current = resolve

      localOptions.onClose = () => {
        onClose()
        resolve({ result: 'dismissed' })
      }

      const modalPrimaryAction = localOptions.primaryAction
      const modalSecondaryAction = localOptions.secondaryAction

      localOptions.primaryAction = async () => {
        let data
        if (modalPrimaryAction) data = await modalPrimaryAction()
        onClose()
        resolve({ result: 'primary', data })
      }

      localOptions.secondaryAction = async () => {
        let data
        if (modalSecondaryAction) data = await modalSecondaryAction()
        onClose()
        resolve({ result: 'secondary', data })
      }

      setModalProps(localOptions)
    })
  }, [])

  useEffect(() => {
    if (modalProps) {
      onOpen()
    }
  }, [modalProps])

  return (
    <ModalContext.Provider value={{ showModal }}>
      {children}
      <Modal isOpen={isOpen} onClose={onClose} {...(modalProps ?? {} as IModalProps)} />
    </ModalContext.Provider>
  )
}

export const useShowModal = () => useContext(ModalContext).showModal

const Modal: React.FC<IModalProps> = ({
  children,
  title,
  description,
  Header,
  primaryAction,
  primaryActionText,
  primaryActionProps = {},
  secondaryAction,
  secondaryActionText,
  withLoading = false,
  secondaryActionProps = {},
  withClose,
  dismissible = true,
  invisible = false,
  size = "lg",
  webSizing = "sm",
  nativeHeight = "auto",
  alignItems = "center",
  buttonsDirection = { base: 'column', md: 'row' },
  contentProps = {},
  bodyProps = {},
  headerProps = {},
  footerProps = {},
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const isMounted = useIsMounted()

  const [primaryProps, secondaryProps] = useMemo(
    () => [
      _.merge({ ...primaryActionProps }, DEFAULT_PRIMARY_ACTION_PROPS),
      _.merge({ ...secondaryActionProps }, DEFAULT_SECONDARY_ACTION_PROPS),
    ],
    [primaryActionProps,
      secondaryActionProps],
  )

  const wrapOperation = (operation?: IButtonAction) => async () => {
    withLoading && setLoading(true)

    if (operation) {
      await operation()
    }

    isMounted && setLoading(false)
  }

  const renderPrimaryButton = !!primaryActionText
  const renderSecondaryButton = !!secondaryActionText

  const maxW = size !== 'lg' ? undefined : Dimensions.width * 0.9
  const _web = size !== 'lg' ? undefined : MODAL_WEB_SIZE_MAP[webSizing]

  useEffect(() => {
    if (isWeb && props.isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'auto';
    }
  }, []);

  return (
    <>
      {/* next line is for quick testing in storybook */}
      {/* <Hidden platform={['web']}> */}
      <Hidden platform={['ios', 'android']}>
        <NativeBaseModal
          size={size}
          backgroundColor="#333440a6"
          animationPreset="fade"
          onLayout={onLayout}
          closeOnOverlayClick={dismissible}
          isKeyboardDismissable={dismissible}
          opacity={invisible ? 0 : undefined}
          {...props}
        >
          <NativeBaseModal.Content
            pointerEvents={invisible ? 'none' : undefined}
            borderRadius={MODAL_BORDER_RADIUS}
            maxW={maxW}
            _web={_web}
            py={{ base: 6, md: 8 }}
            px={{ base: 4, md: 8 }}
            bg={MODAL_BACKGROUND_COLOR}
            {...contentProps}
          >
            {dismissible && (
              <NativeBaseModal.CloseButton
                position="absolute"
                color="text.50"
                w={6}
                h={6}
                top={4}
                right={4}
              />
            )}
            {loading ? (
              <Loader />
            ) : (
              <>
                {title && (
                  <NativeBaseModal.Header
                    borderBottomWidth={0}
                    p={0}
                    pr={{ base: '6', md: 'unset' }}
                    m={0}
                    pb={2}
                    {...headerProps}
                  >
                    {title && (
                      <MarkdownText fontSize="2xl" bold letterSpacing="0.98">
                        {title}
                      </MarkdownText>
                    )}
                  </NativeBaseModal.Header>
                )}

                <NativeBaseModal.Body
                  p={0}
                  m={0}
                  width="auto"
                  {...bodyProps}
                >
                  <Stack space="6">
                    {description && (
                      <MarkdownText color="neutral.400">{description}</MarkdownText>
                    )}
                    {children}
                  </Stack>
                </NativeBaseModal.Body>

                {(renderPrimaryButton || renderSecondaryButton) &&
                  <NativeBaseModal.Footer bg="transparent" p={0} pt="6" {...footerProps}>
                    <Stack
                      flex={1}
                      w="100%"
                      direction={buttonsDirection}
                      alignItems={alignItems}
                      justifyContent="flex-end"
                      space={4}
                    >
                      {renderSecondaryButton && (
                        <LocalButton
                          {...secondaryProps}
                          onPress={wrapOperation(secondaryAction)}>
                          {secondaryActionText}
                        </LocalButton>
                      )}
                      {renderPrimaryButton && (
                        <LocalButton
                          {...primaryProps}
                          onPress={wrapOperation(primaryAction)}>
                          {primaryActionText}
                        </LocalButton>
                      )}
                    </Stack>
                  </NativeBaseModal.Footer>
                }
              </>
            )}
          </NativeBaseModal.Content>
        </NativeBaseModal>
      </Hidden>
      {/* next line is for quick testing in storybook */}
      {/* <Hidden platform={['ios']}> */}
      <Hidden platform={['web']}>
        <Actionsheet
          hideDragIndicator={!dismissible}
          closeOnOverlayClick={false}
          _backdrop={{ ...(!dismissible && { onPress: () => null }) }}
          {...props}
        >
          <Actionsheet.Content
            bg={MODAL_BACKGROUND_COLOR}
            borderTopRadius={ACTIONSHEET_BORDER_RADIUS}
            minH={nativeHeight === 'tall' ? '100%' : undefined}
            paddingTop={nativeHeight === 'tall' ? 8 : undefined}
          >
            <Stack
              flex={nativeHeight === 'tall' ? 1 : undefined}
              w="100%"
              px={4}
              py={8}
            >
              {/* HEADER */}
              {title && (
                <MarkdownText fontSize="2xl" bold letterSpacing="0.98" mb="2">
                  {title}
                </MarkdownText>
              )}
              {/* BODY */}
              <ScrollView>
                <Stack space="6">
                  {description && (
                    <MarkdownText color="neutral.400">{description}</MarkdownText>
                  )}
                  {children}
                </Stack>
              </ScrollView>
              {/* Footer */}
              {(renderPrimaryButton || renderSecondaryButton) &&
                <Stack bg="transparent" p={0} pt="6" {...footerProps}>
                  <Stack space={4}>
                    {renderSecondaryButton && (
                      <LocalButton
                        {...secondaryProps}
                        onPress={wrapOperation(secondaryAction)}>
                        {secondaryActionText}
                      </LocalButton>
                    )}
                    {renderPrimaryButton && (
                      <LocalButton
                        {...primaryProps}
                        onPress={wrapOperation(primaryAction)}>
                        {primaryActionText}
                      </LocalButton>
                    )}
                  </Stack>
                </Stack>
              }
            </Stack>
          </Actionsheet.Content>
        </Actionsheet>
      </Hidden>
    </>
  )
}

export default Modal
