import React from 'react'
import { HStack, Text, Box } from 'native-base'
import Icon from '../../Icon'

export type ValidationStatus = 'valid' | 'invalid' | 'pending';
export interface IPasswordRuleProps {
  status: ValidationStatus
  text: string
}

const colors: Record<ValidationStatus, string> = {
  valid: 'success.400',
  invalid: 'error.400',
  pending: 'text.500', 
}

const PasswordRule = ({ status,  text }: IPasswordRuleProps) => (
  <HStack alignItems="flex-end" _web={{ alignItems: 'center' }} mt="2" space="2">
    {status === 'valid' && (
      <Box color="success.400">
        <Icon name="checkcircleoutlined" color="success.400" size="sm" />
      </Box>
    )}
    {status === 'invalid' && (
      <Box color="error.400">
        <Icon name="closecircleoutlined" color="error.400" size="sm" />
      </Box>
    )}
    <Text fontSize="sm" color={colors[status]}>{text}</Text>
  </HStack>
)

export default PasswordRule
