import React from 'react'
import { Icon, IIconProps, useTheme } from 'native-base'
import { Path, G, Defs, ClipPath, Rect } from 'react-native-svg'

const IbcLowIcon = ({ color, ...props }: IIconProps) => {
  const { colors } = useTheme() as any
  const fillColor = color || colors.text['50']
  return (
    <Icon width="56" height="56" viewBox="0 0 56 56"  {...props}>
      <G clip-path="url(#clip0_5403_27521)">
        <Path
          d="M22.2727 7.50209V14.62H9.33331V46.704H27.2222L27.2576 39.8339H32.6313L32.5959 46.704H37.5454L37.5808 34.8761H22.3434L22.3081 41.7462H14.2828V19.5778H27.2222V12.4599L41.7172 12.4245V46.704H46.6666V7.46667L22.2727 7.50209Z"
          fill={fillColor}
        />
        <Path
          d="M18.9495 22.4817C18.9495 22.4817 18.8434 22.4817 18.8081 22.5525C18.8081 22.5525 18.7727 22.6233 18.7727 22.6942V31.5119C18.7727 31.5119 18.7727 31.6182 18.8081 31.6536C18.8434 31.689 18.8788 31.689 18.9495 31.689H21.0353C21.0353 31.689 21.1414 31.689 21.1767 31.6536C21.1767 31.6536 21.2121 31.5828 21.2121 31.5119V22.6942C21.2121 22.6942 21.2121 22.5879 21.1767 22.5525C21.1414 22.5171 21.106 22.4817 21.0353 22.4817H18.9495Z"
          fill={fillColor}
        />
        <Path
          d="M29.6264 25.1022C29.6264 24.1461 29.3436 23.4732 28.7779 23.0837C28.2123 22.6942 27.3638 22.4817 26.2325 22.4817H22.8032C22.8032 22.4817 22.6971 22.4817 22.6618 22.5525C22.6618 22.5525 22.6264 22.6233 22.6264 22.6942V31.5119C22.6264 31.5119 22.6264 31.6182 22.6618 31.6536C22.6971 31.689 22.7325 31.689 22.8032 31.689H26.3385C27.3638 31.689 28.1769 31.4765 28.8133 31.0516C29.4496 30.6266 29.7678 29.9538 29.7678 29.0685C29.7678 28.5727 29.6618 28.1831 29.485 27.829C29.3082 27.4749 29.0254 27.1916 28.6012 26.9791C28.6012 26.9791 28.6012 26.9437 28.6012 26.9083C29.2729 26.5187 29.5911 25.9167 29.5911 25.1022H29.6264ZM25.1012 24.6773C25.1012 24.6773 25.1012 24.6065 25.1719 24.6065H26.2325C26.8688 24.6065 27.187 24.8543 27.187 25.3147C27.187 25.7751 26.8688 26.0584 26.2325 26.0584H25.1719C25.1719 26.0584 25.1012 26.0584 25.1012 25.9876V24.6773ZM26.3739 29.5997H25.1719C25.1719 29.5997 25.1012 29.5997 25.1012 29.5288V28.1477C25.1012 28.1477 25.1012 28.0769 25.1719 28.0769H26.3739C26.9749 28.0769 27.2931 28.3248 27.2931 28.8206C27.2931 29.3518 26.9749 29.5997 26.3739 29.5997Z"
          fill={fillColor}
        />
        <Path
          d="M33.692 24.8189C33.9041 24.6064 34.1869 24.5002 34.5405 24.5002C34.894 24.5002 35.1768 24.6064 35.3889 24.8189C35.6011 25.0314 35.7071 25.3147 35.7071 25.6688C35.7071 25.7751 35.7425 25.8105 35.8485 25.8105H35.8839L37.9698 25.6688C37.9698 25.6688 38.1819 25.598 38.1819 25.5272C38.1819 24.8897 38.0405 24.3231 37.7223 23.8628C37.4041 23.4024 36.9799 23.0129 36.4496 22.765C35.9192 22.5171 35.2829 22.3754 34.5405 22.3754C33.798 22.3754 33.197 22.5171 32.6314 22.8004C32.0657 23.0837 31.6415 23.4732 31.3586 23.969C31.0405 24.4648 30.899 25.0668 30.899 25.7396V28.3956C30.899 29.0684 31.0405 29.6705 31.3586 30.1662C31.6415 30.662 32.1011 31.087 32.6314 31.3703C33.197 31.6536 33.798 31.7952 34.5405 31.7952C35.2829 31.7952 35.8839 31.6536 36.4496 31.4057C37.0152 31.1224 37.4395 30.7682 37.7223 30.2725C38.0051 29.7767 38.1819 29.2455 38.1819 28.6081C38.1819 28.5372 38.1112 28.4664 37.9698 28.4664L35.8839 28.3602C35.8839 28.3602 35.7778 28.3602 35.7425 28.3602C35.7425 28.3602 35.7071 28.431 35.7071 28.4664C35.7071 28.8205 35.6011 29.1038 35.3889 29.3163C35.1768 29.5288 34.894 29.635 34.5405 29.635C34.1869 29.635 33.9041 29.5288 33.692 29.3163C33.4799 29.1038 33.3738 28.8205 33.3738 28.4664V25.6334C33.3738 25.2793 33.4799 24.996 33.692 24.7835V24.8189Z"
          fill={fillColor}
        />
      </G>
      <Defs>
        <ClipPath id="clip0_5403_27521">
          <Rect width="56" height="56" fill={fillColor} />
        </ClipPath>
      </Defs>
    </Icon>
  )
}
export default IbcLowIcon
