import _ from 'lodash'
import { NativeBaseProvider, useTheme, useToken } from 'native-base'
import { forwardRef, useMemo } from 'react'
import RSelect from 'react-select'

const removeInputThemeDefinition = ({ components, ...rest }: any): any => ({
  ...rest,
  components: {
    ...components,
    Input: {
      defaultProps: {
        borderRadius: 4,
        borderColor: 'transparent',
      },
    },
  },
})

const SelectComponent = forwardRef(
  ({ options, ...props }: SelectProps, ref: any) => {
    const theme = useTheme()
    const [background500, primary500] = useToken('colors', [
      'background.500',
      'primary.500',
    ])

    const newTheme = useMemo(() => removeInputThemeDefinition(theme), [theme])

    return (
      <NativeBaseProvider
        config={{ suppressColorAccessibilityWarning: true }}
        theme={newTheme}>
        <RSelect
          options={options}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          styles={{
            dropdownIndicator: (_, state) => ({
              marginRight: 4,
              color: 'white',
              transition: 'transform .2s',
              ...(state.selectProps.menuIsOpen && {
                transform: 'rotate(-180deg)',
              }),
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: 'none',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: 'white',
            }),
            control: (_, state) => ({
              fontSize: 18,
              display: 'flex',
              padding: `9px 3px`,
              backgroundColor: background500,
              borderRadius: 4,
              ...(state.menuIsOpen && { border: `1px solid ${primary500}` }),
            }),
            container: (baseStyles) => ({
              ...baseStyles,
              fontFamily: 'Barlow',
            }),
            menu: () => ({
              margin: 0,
            }),
            menuList: (styles, state) => ({
              ...styles,
              margin: 0,
              backgroundColor: background500,
              color: 'white',
              borderRadius: 4,
              padding: 0,
              ...(state.selectProps.menuIsOpen && {
                border: `1px solid ${primary500}`,
                borderTop: 'none',
              }),
            }),
            option: (_, state) => ({
              padding: '13px 16px',
              fontSize: 18,
              color: 'white',
              fontFamily: 'Barlow',
              ...(state.isFocused && { backgroundColor: primary500 }),
            }),
          }}
          {...props}
          ref={ref}
        />
      </NativeBaseProvider>
    )
  },
)

export type SelectProps = React.ComponentProps<typeof RSelect>

export default SelectComponent
