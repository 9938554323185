import React from 'react'
import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'

import Base, { IIsolatedBaseProps } from './Base.field'

export interface IFormCustomProps extends IIsolatedBaseProps {
  labelProps?: IFormControlProps
  children: React.ReactNode
}

const FormCustom = ({
  children,
  labelProps = {},
  labelTextProps = {},
  error,
  label,
  showAsRequired,
  showTooltip,
  tooltipProps,
  showAsOptional,
}: IFormCustomProps) => (
  <Base
    error={error}
    label={label}
    showTooltip={showTooltip}
    tooltipProps={tooltipProps}
    showAsRequired={showAsRequired}
    showAsOptional={showAsOptional}
    labelTextProps={labelTextProps}
    {...labelProps}>
    {children}
  </Base>
)

export default FormCustom
