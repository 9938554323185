import React, { useCallback } from 'react'
import { Button, Heading, Hidden, HStack, Stack, Text, View, VStack } from 'native-base'

import Icon, { IIconProps } from '../../Icon'
import type { IconName } from '../../Icon/types.icon'
import CMSText, { ICMSTextProps } from '../CMSText'
import DataPoint, { IDataPointProps } from '../DataPoint'
import { IColors } from 'native-base/lib/typescript/theme/base/colors'
import { VariantType } from 'native-base/lib/typescript/components/types'
import { IBillingSwitchProps } from '../BillingSwitch'

import { PlanPrice } from './Components.planCard'
import { IViewProps } from 'native-base/lib/typescript/components/basic/View/types'

interface ICollapsible {
  defaultOpened?: boolean
}

export interface IPlan {
  type: string
  sku: string
  color?: IColors
  icon: IIconProps
  title: string
  headTitle?: string
  subtitle?: ICMSTextProps
  customPrice?: string
  mainActionTitle: string
  datapoints?: IDataPointProps[]
  billable?: boolean
  defaultOpened?: boolean
  mainActionVariant?: VariantType<'Button'>
  footer?: { title: string; subtitle: string }
  price?: { frequency?: string; value: string }
  ui?: IPlanUI
}

export interface IPlanUI {
  title?: string
  icon?: IIconProps
  color?: IColors
  flags?: {
    allow_annual_purchases?: boolean
  }
  plan_card?: {
    title_color?: string
    bar_color?: string
    datapoints?: IDataPointProps[]
    mainWrapperStyles?: IViewProps
    iconWrapperStyles?: IViewProps
    buttonWrapperStyles?: IViewProps
    mainActionVariant?: VariantType<'Button'>
    subtitle?: ICMSTextProps
    purchase_style_variant?: "first_purchase" | "active" | "downgrade" | "upgrade"
  }
}

export interface IPlanCardProps {
  minWidth?: string
  plan: IPlan
  active?: boolean
  isMobile?: boolean
  onPress: () => void
  onProcessLink?: () => void
  BillingSwitch?: React.FC<Pick<IBillingSwitchProps, 'isDisabled'>>
  Collapsible: React.FC<ICollapsible>
}

const getKey = (prefix: string, index: number) => `${prefix}-${index}`

const PlanCard = ({
  minWidth,
  plan,
  onPress,
  onProcessLink,
  active = false,
  Collapsible,
  isMobile = false,
  BillingSwitch,
}: IPlanCardProps) => {
  const {
    title,
    headTitle,
    price,
    color: deprecatedColor,
    footer,
    subtitle: deprecatedSubtitle,
    customPrice,
    mainActionTitle,
    icon: deprecatedIcon = {},
    datapoints: deprecatedDatapoints = [],
    billable = false,
    defaultOpened = false,
    mainActionVariant: deprecatedMainActionVariant,
  } = plan

  const plan_card = plan.ui?.plan_card || {} as IPlanUI["plan_card"]
  const datapoints = plan_card?.datapoints || deprecatedDatapoints
  const icon = plan.ui?.icon || deprecatedIcon
  const mainActionVariant = plan.ui?.plan_card?.mainActionVariant || deprecatedMainActionVariant || 'solid'
  const color = plan.ui?.color || deprecatedColor
  const subtitle = plan.ui?.plan_card?.subtitle || deprecatedSubtitle

  const Wrapper = useCallback(
    ({ children, defaultOpened }) =>
      isMobile ? (
        <Collapsible defaultOpened={defaultOpened}>{children}</Collapsible>
      ) : (
        <View w="100%">{children}</View>
      ),
    [isMobile],
  ) as React.FC<ICollapsible>

  const { name: iconName, ...iconProps } = icon as any

  const purchase_style_variant = plan_card?.purchase_style_variant || 'first_purchase'

  const variantMap = {
    first_purchase: 'solid',
    upgrade: 'solid',
    active: 'outline',
    downgrade: 'outline',
  }

  const textColorMap = {
    first_purchase: 'neutral.900',
    upgrade: 'neutral.900',
    active: '#ffffff',
    downgrade: '#ffffff',
  }

  const ctaTextMap = {
    active: 'Current Plan',
    downgrade: 'Select Plan',
    first_purchase: 'Purchase',
    upgrade: `Upgrade to ${title}`,
  }

  const ctaButtonProps = {
    isDisabled: active,
    opacity: active ? 50 : 100,
    variant: variantMap[purchase_style_variant],
    onPress: onPress,
    _text: { color: active ? '#fff' : textColorMap[purchase_style_variant] },
  }

  const allowAnnualPurchases = plan.ui?.flags?.allow_annual_purchases
  const isToggleVisible = billable && allowAnnualPurchases

  return (
    <View
      overflow="hidden"
      minWidth={minWidth}
      borderWidth="1"
      borderColor="background.500"
      backgroundColor="background.700"
      borderRadius="16"
      px="6"
      py="10"
      pb={{ base: '4', lg: '10' }}
      flex="1"
      _web={{ flex: { base: 'unset', lg: '1' } }}
      position={"relative"}
    >
      <Stack top="0" left="0" right="0" position={"absolute"} alignItems={"center"}>
        <View bg={plan_card?.bar_color || color} h="2.5" maxW="167" w="100%" borderBottomRadius="8" mb="2" />
      </Stack>

      <Stack space={6}>
        <Stack flexDirection="row" justifyContent={"space-between"} space={6}>
          <Stack>
            <Heading p={0} size="2xl" fontWeight="600" color={plan_card?.title_color || color} lineHeight="1.2em">
              {title}
            </Heading>
            {subtitle && (
              <CMSText {...subtitle} color={"neutral.500"} fontWeight={"500"} lineHeight="1.2em" />
            )}
          </Stack>
          <Stack>
            {price ? (
              <VStack flex="1" justifyContent="flex-end">
                <PlanPrice value={price.value} frequency={price.frequency} />
                {isToggleVisible ? (
                  <View flex="1" >
                    {BillingSwitch && (
                      <BillingSwitch isDisabled={active} />
                    )}
                  </View>
                ) : null}
              </VStack>
            ) : (
              <Text
                flex="0.5"
                _web={{ flex: '0' }}
                px="2"
                mt="2"
                textAlign="center"
                fontSize="lg"
                italic
                fontWeight="bold"
                color="text.50">
                {customPrice}
              </Text>
            )}
          </Stack>
        </Stack>
        <Stack>
          <View w="100%" {...plan?.ui?.plan_card?.buttonWrapperStyles}>
            <Button
              {...ctaButtonProps}
              colorScheme='amber'
            >
              {ctaTextMap[purchase_style_variant]}
            </Button>
          </View>
        </Stack>
        <Stack>
          <Wrapper defaultOpened={defaultOpened}>
            <Stack space={6}>
              {datapoints.map((datapoint: any, index) => (
                <DataPoint
                  {...datapoint}
                  onProcessLink={onProcessLink}
                  mobileWeb={isMobile}
                  key={getKey(`${title}-planDatapoint`, index)}
                />
              ))}
            </Stack>
          </Wrapper>
        </Stack>
      </Stack>
    </View>
  )
}

export default PlanCard
