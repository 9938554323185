import React, { memo, MutableRefObject } from 'react'
import { FormControl, HStack, IInputProps, Text, View } from 'native-base'
import Tooltip, { ITooltipProps } from '../../__Helpers/Tooltip'

import { IFormControlProps } from 'native-base/lib/typescript/components/composites/FormControl'
import Icon from '../../Icon'

export type NativeBaseRef = MutableRefObject<any> | undefined

export interface IIsolatedBaseProps {
  label?: string
  labelTextProps?: Object
  error?: string | JSX.Element
  showAsOptional?: boolean
  showAsRequired?: boolean
  showTooltip?: boolean
  tooltipProps?: ITooltipProps
  showPartnerTooltip?: boolean
  partnerTooltipProps?: ITooltipProps
}

export interface IBaseProps extends IFormControlProps, IIsolatedBaseProps {
  InputRightElement?: IInputProps['InputRightElement']
}

const isDisabledColor = {
  color: 'text.500',
}

const ChildrenWrapper = memo(
  ({ children, wrap = false }: { children: React.ReactNode; wrap?: boolean }) =>
    wrap ? (
      <HStack justifyContent="center" alignItems="center">
        {children}
      </HStack>
    ) : (
      <>{children}</>
    ),
)

const PartnerIcon = (<Icon name={"eagleviewshort"} size={4} />)

export default ({
  label,
  labelTextProps,
  error,
  children,
  showAsOptional,
  showAsRequired,
  showTooltip: showTooltipProp,
  tooltipProps = {},
  showPartnerTooltip: showPartnerTooltipProp,
  partnerTooltipProps = {},
  isDisabled,
  InputRightElement = undefined,
  ...props
}: IBaseProps) => (
  <>
    <FormControl {...props}>
      {label &&
        <View flexDirection="row" alignItems="center" mb={2}>
          <Text
            letterSpacing={0.19}
            fontWeight={500}
            fontSize="sm"
            mr={showTooltipProp ? 1 : 0}
            {...(isDisabled ? isDisabledColor : {})}
            {...labelTextProps}>
            {showAsRequired && '*'}{label}
            {showAsOptional && (
              <Text style={{ fontStyle: 'italic' }} fontWeight="400" fontSize="xs">
                {' '}
                (optional)
              </Text>
            )}
          </Text>
          {showTooltipProp && <Tooltip {...tooltipProps} />}
          {showPartnerTooltipProp && <Tooltip {...partnerTooltipProps} customIcon={PartnerIcon} />}
        </View>}
      <ChildrenWrapper wrap={!!InputRightElement}>
        {children}
        {InputRightElement}
      </ChildrenWrapper>
      {error && (
        <HStack mt="2" space="2" color="error.400" alignItems="center">
          <Icon name="warningtriangleoutlined" color="error.400" size="5"/>
          <Text color="error.400" fontSize="sm" fontWeight={500}>
            {error}
          </Text>
        </HStack>
      )}
    </FormControl>
  </>
)
