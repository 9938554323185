import React from 'react'
import { Icon, IIconProps } from 'native-base'
import {
  Circle,
  Defs,
  G,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg'

const BusinessPlanIcon = (props: IIconProps) => (
  <Icon
    {...props}
    id="Business_-_Illustration-businessplan-J0hX4k"
    viewBox="0 0 164.856 97">
    <Defs>
      <LinearGradient
        id="linear-gradient-businessplan-J0hX4k"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <Stop offset="0" stopColor="#fcb627" />
        <Stop offset="1" stopColor="#866407" />
      </LinearGradient>
      <LinearGradient
        id="linear-gradient-2-businessplan-J0hX4k"
        x1="0.5"
        x2="0.5"
        y2="1"
        gradientUnits="objectBoundingBox">
        <Stop offset="0" stopColor="#fab701" />
        <Stop offset="1" stopColor="#8d5b0c" />
      </LinearGradient>
    </Defs>
    <G id="sun" transform="translate(-1090.14 -707)">
      <Circle
        id="Ellipse_1-businessplan-J0hX4k"
        data-name="Ellipse 1"
        cx="10"
        cy="10"
        r="10"
        transform="translate(1177.068 728)"
        fill="#fed889"
      />
      <G
        id="Ellipse_2-businessplan-J0hX4k"
        data-name="Ellipse 2"
        transform="translate(1172.068 723)"
        fill="none"
        stroke="#fed889"
        stroke-width="1.5">
        <Circle cx="15" cy="15" r="15" stroke="none" />
        <Circle cx="15" cy="15" r="14.25" fill="none" />
      </G>
      <G
        id="Ellipse_3-businessplan-J0hX4k"
        data-name="Ellipse 3"
        transform="translate(1166.068 716)"
        fill="none"
        stroke="#fed889"
        stroke-width="1">
        <Circle cx="21.5" cy="21.5" r="21.5" stroke="none" />
        <Circle cx="21.5" cy="21.5" r="21" fill="none" />
      </G>
      <G
        id="Ellipse_4-businessplan-J0hX4k"
        data-name="Ellipse 4"
        transform="translate(1156.068 707)"
        fill="none"
        stroke="#fed889"
        stroke-width="0.25">
        <Circle cx="31" cy="31" r="31" stroke="none" />
        <Circle cx="31" cy="31" r="30.875" fill="none" />
      </G>
    </G>
    <Rect
      id="Front_Right_House-businessplan-J0hX4k"
      data-name="Front_Right House"
      width="44.414"
      height="47.642"
      transform="translate(114.133 47.925)"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
      fill="url(#linear-gradient-businessplan-J0hX4k)"
    />
    <Path
      id="Roof_Right_House-businessplan-J0hX4k"
      data-name="Roof_Right House"
      d="M72.3,0,85.46,17.929h44.414L116.716,0Z"
      transform="translate(28.673 29.996)"
      fill="#fa9001"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
    />
    <Path
      id="Front_Center_House-businessplan-J0hX4k"
      data-name="Front_Center House"
      d="M68.46,14.654,63.938,8.418,48.824,29V62.233h29.9L78.866,29Z"
      transform="translate(19.362 33.335)"
      fill="#fcb627"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
    />
    <Path
      id="Roof_Center_House-businessplan-J0hX4k"
      data-name="Roof_Center House"
      d="M57.638,8.418,41.854,29h9.735L66.7,8.418Z"
      transform="translate(16.597 33.334)"
      fill="#fa9000"
    />
    <Path
      id="Front_Left_House-businessplan-J0hX4k"
      data-name="Front_Left House"
      d="M53.363,20.241H20.708v37.3h29.9l-.367-33.23Z"
      transform="translate(8.212 38.023)"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
      fill="url(#linear-gradient-2-businessplan-J0hX4k)"
    />
    <Path
      id="Roof_Left_House-businessplan-J0hX4k"
      data-name="Roof_Left House"
      d="M56.608,24.624l1.776-2.317L48.715,9.146H12.556L23.94,24.641Z"
      transform="translate(4.979 33.623)"
      fill="#fa9001"
      stroke="rgba(0,0,0,0)"
      stroke-miterlimit="10"
      stroke-width="1"
    />
    <Path
      id="Sides_Shadow_-businessplan-J0hX4k"
      data-name="Sides (Shadow)"
      d="M100.978,0,87.822,17.929,98.228,32.341l-.141,33.23h-29.9V32.341H58.451l.367,33.23h-29.9v-37.3L17.537,12.774,6.31,28.268v37.3H0V67H164.857V65.571H114.133V17.929Z"
      transform="translate(0 29.996)"
      fill="#141C4D"
    />
    <Path
      id="Path_81-businessplan-J0hX4k"
      data-name="Path 81"
      d="M4119.91-21763.859c3.064,4.012,13.5,18.334,13.5,18.334"
      transform="translate(4152.843 -21715.385) rotate(180)"
      fill="none"
      stroke="#fed889"
      stroke-linecap="round"
      stroke-width="1.5"
      stroke-dasharray="10"
    />
    <Path
      id="Path_82-businessplan-J0hX4k"
      data-name="Path 82"
      d="M4119.91-21763.859c3.064,4.012,13.5,18.334,13.5,18.334"
      transform="translate(4157.338 -21734.721) rotate(180)"
      fill="none"
      stroke="#fed889"
      stroke-linecap="round"
      stroke-width="1.5"
      stroke-dasharray="10"
    />
    <Path
      id="Path_76-businessplan-J0hX4k"
      data-name="Path 76"
      d="M4119.91-21763.859c3.064,4.012,13.5,18.334,13.5,18.334"
      transform="translate(-4086.482 21801)"
      fill="none"
      stroke="#fed889"
      stroke-linecap="round"
      stroke-width="1.5"
    />
    <Path
      id="Path_77-businessplan-J0hX4k"
      data-name="Path 77"
      d="M4119.91-21763.859c3.064,4.012,13.5,18.334,13.5,18.334"
      transform="translate(-4082.482 21793)"
      fill="none"
      stroke="#fed889"
      stroke-linecap="round"
      stroke-width="1.5"
    />
  </Icon>
)

export default BusinessPlanIcon
