import React, { forwardRef, useState } from 'react'
import { VStack, Button } from 'native-base'

import FormInput, { IFormInputProps } from './Input.field'
import PasswordRule, { ValidationStatus } from '../../__Helpers/PasswordRule'

const localeText = {
  showPassword: 'Show',
  hidePassword: 'Hide',
  passwordRuleLength: 'At least 8 characters',
  passwordRuleNumber: 'Must contain at least 1 number',
  passwordRuleLowercaseLetter: 'Must contain at least 1 lowercase letter',
}

const LENGTH_REGEXP = /^.{8,}/
const NUMBER_REGEXP = /^.*\d.*$/
const LOWERCASE_LETTER_REGEXP = /^.*[a-z].*/

export interface IPasswordRules {
  rules: {
    length: RegExp
    number: RegExp
    lowercaseLetters: RegExp
  }
}

export type PasswordError = Record<keyof IPasswordRules['rules'], boolean> | string;

export interface IFormPasswordProps
  extends Omit<IFormInputProps, 'InputRightElement' | 'error'> {
  showRules: boolean
  error?: PasswordError
}


const SignupPassword = forwardRef<any, IFormPasswordProps>(
  ({ value = '', showRules = false, error, ...rest }: IFormPasswordProps, ref) => {
    const [showPassword, setShowPassword] = useState(false)

    const onTogglePasswordVisibility = () => {
      setShowPassword(!showPassword)
    }

    const rules: Record<keyof IPasswordRules['rules'], ValidationStatus> = {
      length: 'pending',
      number: 'pending',
      lowercaseLetters: 'pending',
    }
    if (error && typeof error === 'object' && Object.keys(error).length) {
      rules.length = error.length ? 'invalid' : 'valid'
      rules.number = error.number ? 'invalid' : 'valid'
      rules.lowercaseLetters = error.lowercaseLetters ? 'invalid' : 'valid'
    } else {
      rules.length = LENGTH_REGEXP.test(value) ? 'valid' : 'pending'
      rules.number = NUMBER_REGEXP.test(value) ? 'valid' : 'pending'
      rules.lowercaseLetters = LOWERCASE_LETTER_REGEXP.test(value)
        ? 'valid'
        : 'pending'
    }

    return (
      <VStack w="100%" justifyContent="flex-start">
        <FormInput
          textContentType="password"
          {...rest}
          ref={ref}
          value={value}
          type={showPassword ? 'text' : 'password'}
          error={typeof error === 'string' ? error : undefined}
          InputRightElement={
            <Button
              w="auto"
              variant="link"
              size="lg"
              isDisabled={!value}
              onPress={onTogglePasswordVisibility}
              _text={{
                color: 'cyan.400'
              }}
              _disabled={{
                _text: {
                  color: 'cyan.500'
                }
              }}>
              {showPassword ? localeText.hidePassword : localeText.showPassword}
            </Button>
          }
          mr={0}
        />
        {showRules && (
          <>
            <PasswordRule
              status={rules.length}
              text={localeText.passwordRuleLength}
            />
            <PasswordRule
              status={rules.number}
              text={localeText.passwordRuleNumber}
            />
            <PasswordRule
              status={rules.lowercaseLetters}
              text={localeText.passwordRuleLowercaseLetter}
            />
          </>
        )}
      </VStack>
    )
  },
)

const PASSWORD_RULES = {
  length: LENGTH_REGEXP,
  number: NUMBER_REGEXP,
  lowercaseLetters: LOWERCASE_LETTER_REGEXP,
}

export default Object.assign(SignupPassword, {
  rules: PASSWORD_RULES,
  validate: {
    length: (value: string) => LENGTH_REGEXP.test(value),
    number: (value: string) => NUMBER_REGEXP.test(value),
    lowercaseLetters: (value: string) => LOWERCASE_LETTER_REGEXP.test(value),
  }
})
