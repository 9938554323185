import React from 'react'
import { Text, VStack, HStack, Divider, View, IBoxProps } from 'native-base'
import Icon from '../../Icon'
import { IconName } from '../../Icon/types.icon'

const MONTH_FREQUENCY = 'mo'
const ANNUAL_FREQUENCY = 'yr'
const FREQUECY_VALUES = [MONTH_FREQUENCY, ANNUAL_FREQUENCY] as const
type PriceFrequencyType = typeof FREQUECY_VALUES[number]
interface IPlanBannerWithoutHeaderProps {
  icon?: IconName
  title?: string
  subTitle?: string
  renewTag?: string
  price?: string
  marketingText?: string
  priceFrequency?: PriceFrequencyType
  isDisabled?: boolean
  titleProps?: Record<string, unknown>
}

export interface IPlanBannerProps extends IPlanBannerWithoutHeaderProps {
  header?: React.ReactNode | string
  divider?: boolean
  noBackground?: boolean
  iconFull?: {
    size?: string
    color?: string
  }
}

const PlanBanner = ({
  title,
  subTitle,
  renewTag,
  price,
  marketingText,
  priceFrequency = MONTH_FREQUENCY,
  isDisabled = false,
  icon,
  titleProps,
}: IPlanBannerWithoutHeaderProps) => (
  <VStack
    w="100%"
    h="100%"
    p="5"
    flex={1}
    opacity={isDisabled ? 0.4 : 1}
    justifyContent="space-between">
    <HStack justifyContent="center" alignItems="center" flex={2}>
      <HStack alignItems="center" flex={1} mb="1">
        {icon && <Icon name={icon} size="6" />}
        <Text
          ml="1"
          _web={{ verticalAlign: 'center' }}
          fontSize="xl"
          color="primary.500"
          letterSpacing={0.05}
          fontWeight={300}
          {...titleProps}>
          {title}
        </Text>
        {renewTag && (
          <Text
            ml="1"
            flex="1"
            textAlign="right"
            justifyContent="flex-end"
            fontSize="xs"
            color="neutral.500"
            letterSpacing={0.05}
            fontWeight={500}>
            {renewTag}
          </Text>
        )}
      </HStack>
      {price && (
        <View flex={2}>
          <Text
            fontSize="xl"
            fontWeight={400}
            letterSpacing={0.05}
            color="secondary.500"
            textAlign="right">
            {price && `$${price}`}
            <Text
              fontWeight={400}
              letterSpacing={0.05}
              color="secondary.500"
              fontSize="md">
              {priceFrequency && `/${priceFrequency}`}
            </Text>
          </Text>
        </View>
      )}
    </HStack>
    <HStack justifyContent="flex-start" alignItems="flex-end" flex={1}>
      <Text fontSize="2xs" fontWeight="normal">
        {subTitle}
      </Text>
      {marketingText && (
        <Text
          flex={2}
          textAlign="right"
          letterSpacing={0.02}
          fontWeight="normal"
          fontSize="2xs">
          {marketingText}
        </Text>
      )}
    </HStack>
  </VStack>
)

interface IconFullProps {
  iconName: IconName
  iconSize?: string
  iconColor?: string
}
const IconFullElement = ({
  iconName,
  iconSize = '10',
  iconColor = 'primary.500',
}: IconFullProps) => (
  <Icon paddingRight={1} name={iconName} size={iconSize} color={iconColor} />
)

const PlanBannerWithHeader = ({
  header,
  divider = true,
  noBackground = false,
  ...props
}: IPlanBannerProps) => {
  return (
    <View
      display="flex"
      flexDirection="column"
      bg={noBackground ? 'transparent' : "background.700"}
      borderRadius={16}
      px="5">
      {header && <View px="5">{header}</View>}
      {header && divider && <Divider />}
      {<PlanBanner {...props} />}
    </View>
  )
}

export default PlanBannerWithHeader
