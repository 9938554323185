import React, { createContext, useContext } from 'react'

export const MODULES = {
  ONECLICK_SAMPLE_V1: 'oneclick:sample_module:1.0.0',

  // first party detail modules
  ONECLICK_JURISDICTION_V1: "oneclick:jurisdiction:1.0.0",
  ONECLICK_CODE_DETAIL_V1: "oneclick:code_detail:1.0.0",
  ONECLICK_TOOLS_V1: "oneclick:tools:1.0.0",
  ONECLICK_DETAIL_PDF_V1: "oneclick:detail_pdfs:1.0.0",
  ONECLICK_WEATHER_PDF_V1: "oneclick:weather_pdfs:1.0.0",
  ONECLICK_MUNICIPALITY_CONTACT_V1: "oneclick:municipality_contact:1.0.0",
  ONECLICK_DRONE_V1: "oneclick:drone:1.0.0",
  ONECLICK_ROOF_V1: "oneclick:roof:1.0.0",
  ONECLICK_CODES_ADOPTED_V1: "oneclick:codes_adopted:1.0.0",

  // third party detail modules
  HAILTRACE_HAIL_V1: "hailtrace:hail:1.0.0",
  HAILTRACE_WIND_V1: "hailtrace:wind:1.0.0",
  HAILTRACE_TAG_V1: "hailtrace:tag:1.0.0",

  // cta modules
  ONECLICK_CODE_CTA_V1: "oneclick:code_cta:1.0.0",
  ONECLICK_WEATHER_CTA_V1: "oneclick:weather_cta:1.0.0",
  ONECLICK_CODE_AND_WEATHER_CTA_V1: "oneclick:code_and_weather_cta:1.0.0",
  ONECLICK_MUNICIPALITY_CONTACT_CTA_V1: "oneclick:municipality_contact_cta:1.0.0",
  ONECLICK_DRONE_CTA_V1: "oneclick:drone_cta:1.0.0",
}

export const ModuleContext = createContext({ simpleModules: ['oneclick:sample_module:1.0.0'] })


const isModuleSupported = (key, modules = []) => modules.includes(key)

const simpleModule = (key, moduleLevelFallbackSupport = false) => {
  const higherOrderComponent = (WrappedComponent) => {
    const SimpleModule = (props) => {
      const { fallbackSupported, simpleModules: propModules, ...remainingProps } = props
      const { simpleModules: contextModules } = useContext(ModuleContext)

      let simpleModules = propModules || contextModules

      const shouldFallback = !!(
        (moduleLevelFallbackSupport || fallbackSupported) &&
        typeof simpleModules === 'undefined'
      )

      if (Array.isArray(simpleModules)) simpleModules = [...simpleModules, MODULES.ONECLICK_SAMPLE_V1]

      if (shouldFallback || isModuleSupported(key, simpleModules)) {
        return <WrappedComponent {...remainingProps} />
      }

      return null
    }

    SimpleModule.displayName = `SimpleModule(${getDisplayName(
      WrappedComponent,
    )})`
    return SimpleModule
  }

  return higherOrderComponent
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default simpleModule
