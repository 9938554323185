import React from 'react'
import { Button, Stack, useBreakpointValue, } from 'native-base'

import MarkdownText from '../../../components/PresentationalComponents/MarkdownText'
import Icon from '../../../components/Icon'

import Snackbar from '../Snackbar'
import { SnackbarVariantsType } from 'src/components/__Helpers/types'

type IButtonAction = (() => void) | (() => Promise<void>)

export interface IMessageBannerProps {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  primaryActionText?: string | React.ReactNode
  primaryAction?: IButtonAction
  snackbarMessage?: string
  snackbarTitle?: string
  snackbarVariant?: SnackbarVariantsType
}

const MessageBanner: React.FC<IMessageBannerProps> = ({
  children,
  title,
  description,
  primaryActionText,
  primaryAction = () => null,
  snackbarMessage,
  snackbarTitle,
  snackbarVariant,
}) => {
  const rightIcon = useBreakpointValue({
    base: <Icon isAntDesign name="arrowrightoutlined" color="amber.500" size="3" />,
    md: () => null
  });

  const showSnackbar = !!snackbarMessage || !!snackbarTitle
  const showButton = !!primaryActionText

  return (
    <Stack
      borderRadius={16}
      borderColor="background.input"
      backgroundColor="background.boxes"
      borderWidth="1"
      direction={{ base: 'column', md: 'row' }}
      px="6"
      py="4"
      space={{ base: '4', md: '6' }}
    >
      <Stack flex={{ md: '1' }} space="2">
        <MarkdownText bold fontSize={{ base: 'lg', md: '2xl' }} lineHeight={{ base: "1.2em" }}>
          {title}
        </MarkdownText>
        <Stack>
          <MarkdownText fontSize={{ base: 'xs', md: 'md' }} lineHeight={{ base: "1.2em" }} fontWeight="400">
            {description}
          </MarkdownText>
        </Stack>
      </Stack>
      <Stack space={{ base: '2', md: '3' }} alignItems={{ base: 'flex-end', md: 'center' }} justifyContent="center">
        {showSnackbar ? (
          <Stack minWidth={{ base: "100%", md: undefined }}>
            <Snackbar
              title={snackbarTitle}
              message={snackbarMessage}
              variant={snackbarVariant}
            />
          </Stack>
        ) : null}
        {showButton ? (
          <Stack minWidth={{ md: 276 }}>
            <Button
              height={{ sm: 'auto', md: '48px' }}
              width={{ base: 'auto', md: '100%' }}
              padding={{ base: '0', md: undefined }}
              rightIcon={rightIcon}
              variant={{ base: 'link', md: 'outline' }}
              size={{ base: 'xs', md: 'lg' }}
              colorScheme="amber"
              onPress={primaryAction}
            >
              {primaryActionText}
            </Button>
          </Stack>
        ) : null}
      </Stack>
    </Stack >
  )
}

export default MessageBanner
