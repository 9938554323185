import React from 'react'
import { Stack } from 'native-base'

import { SnackbarVariantsType, SNACKBAR } from '../../../components/__Helpers/types'

import MarkdownText from '../../../components/PresentationalComponents/MarkdownText'

import Icon, { IIconPropsWithAntDesign } from '../../../components/Icon'

export interface ISnackbarProps {
  title?: string | React.ReactNode
  message?: string | React.ReactNode
  variant?: SnackbarVariantsType
  onPress?: () => void
}

const iconProps = {
  [SNACKBAR.success]: {
    isAntDesign: true,
    name: "checkcirclefilled",
    color: "success.400",
  },
  [SNACKBAR.error]: {
    isAntDesign: true,
    name: "closecirclefilled",
    color: "error.400",
  },
  [SNACKBAR.info]: {
    isAntDesign: true,
    name: "warningfilled",
    color: "cyan.400",
  },
}

const borderColor = {
  [SNACKBAR.success]: 'success.500',
  [SNACKBAR.error]: 'error.500',
  [SNACKBAR.info]: 'background.input',
}

const Snackbar = (props: ISnackbarProps) => {
  const {
    onPress = () => null,
    variant = SNACKBAR.info,
    title,
    message,
  } = props
  return (
    <Stack
      p={4}
      borderRadius={12}
      space={4}
      minH={6}
      minW={6}
      background="background.input"
      borderWidth={1}
      borderColor={borderColor[variant]}
    >
      <Stack direction="row" space="4">
        <Stack>
          <Icon size="5" {...iconProps[variant] as IIconPropsWithAntDesign} />
        </Stack>
        <Stack flex="1" space="2"
          justifyContent="center"
        >
          {title ? (
            <MarkdownText fontSize="md" fontWeight="bold" lineHeight="1.2em">{title}</MarkdownText>
          ) : null}
          {message ? (
            <MarkdownText fontSize="sm" fontWeight="medium" lineHeight="1.2em">{message}</MarkdownText>
          ) : null}
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Snackbar
