export const SNACKBAR = {
  info: 'info' as const,
  error: 'error' as const,
  success: 'success' as const,
}

const SNACKBAR_VARIANTS = [
  SNACKBAR.info,
  SNACKBAR.error,
  SNACKBAR.success,
]

export type SnackbarVariantsType = typeof SNACKBAR_VARIANTS[number]

export const STATUS = {
  info: 'info' as const,
  error: 'error' as const,
  success: 'success' as const,
  addressInfoNotFoundNative: 'addressInfoNotFoundNative' as const,
}

export const STATUSES = [
  STATUS.info,
  STATUS.error,
  STATUS.success,
  STATUS.addressInfoNotFoundNative,
] as const

export type StatusType = typeof STATUSES[number]

export const STATUS_TITLE: Record<StatusType, string> = {
  [STATUS.info]: 'Info',
  [STATUS.error]: 'Error',
  [STATUS.success]: 'Success',
  [STATUS.addressInfoNotFoundNative]: 'addressInfoNotFoundNative',
}
